// Core libraries: React, React Bootstrap
import React, { Component } from "react";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";

import { trackPromise } from "react-promise-tracker";

class Clustered extends Component {
  constructor(props) {
    super(props);
    this.state = {
      treeData: [],
      treeData2: [],
    };
  }
  componentDidMount() {
    trackPromise(
      Promise.all([
        fetch(
          `https://ids-express-server.azurewebsites.net/ratio?provider=` +
            this.props.provider
        )
          .then((response) => response.json())
          .then((data) => {
            this.setState({
              treeData: data.rowData.recordset,
              treeData2: data.rowData2.recordset,
            });
          }),
      ])
    );

    var root = am5.Root.new("clustered");

    this.root = root;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.treeData !== this.state.treeData ||
      prevProps.layer !== this.props.layer
    ) {
      if (this.props.layer.value === "Layer_2") {
        var data = this.state.treeData2;
      } else {
        var data = this.state.treeData;
      }

      this.root.dispose();
      var root = am5.Root.new("clustered");
      this.root = root;

      this.root.setThemes([am5themes_Animated.new(this.root)]);

      // Create chart
      // https://www.amcharts.com/docs/v5/charts/xy-chart/
      var chart = this.root.container.children.push(
        am5xy.XYChart.new(this.root, {
          panX: false,
          panY: false,
          wheelX: "panX",
          wheelY: "zoomX",
          layout: this.root.horizontalLayout,
          arrangeTooltips: false,
        })
      );

      // Use only absolute numbers
      this.root.numberFormatter.set("numberFormat", "#.#s'%");

      // Add legend
      // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
      var legend = chart.children.push(
        am5.Legend.new(this.root, {
          centerX: am5.p50,
          x: am5.p50,
        })
      );

      var yAxis = chart.yAxes.push(
        am5xy.CategoryAxis.new(this.root, {
          categoryField: "Layer",
          renderer: am5xy.AxisRendererY.new(this.root, {
            inversed: true,
            cellStartLocation: 0.1,
            cellEndLocation: 0.9,
          }),
        })
      );

      yAxis.data.setAll(data);

      yAxis.get("renderer").labels.template.setAll({
        oversizedBehavior: "fit",
        maxHeight: 20,
      });

      var xAxis = chart.xAxes.push(
        am5xy.ValueAxis.new(this.root, {
          calculateTotals: true,
          min: -100,
          max: 100,
          renderer: am5xy.AxisRendererX.new(this.root, {
            minGridDistance: 50,
          }),
        })
      );

      var xRenderer = yAxis.get("renderer");
      xRenderer.axisFills.template.setAll({
        fill: am5.color(0x000000),
        fillOpacity: 0.0,
        visible: true,
      });

      function createSeries(rt, field, name, color, dt) {
        var series = chart.series.push(
          am5xy.ColumnSeries.new(rt, {
            xAxis: xAxis,
            yAxis: yAxis,
            name: name,
            valueXField: field,
            valueXShow: "valueXTotalPercent",
            categoryYField: "Layer",
            sequencedInterpolation: true,
            stacked: true,
            fill: color,
            stroke: color,
            calculateAggregates: true,
            tooltip: am5.Tooltip.new(rt, {
              keepTargetHover: true,
            }),
          })
        );

        series.columns.template.setAll({
          tooltipText: "{categoryY} - {name}: {valueX}",
        });

        series.columns.template.setAll({
          height: am5.p100,
        });

        series.bullets.push(function (rt, series) {
          return am5.Bullet.new(rt, {
            locationX: 0.5,
            locationY: 0.5,
            sprite: am5.Label.new(rt, {
              fill: am5.color(0xffffff),
              centerX: am5.p50,
              centerY: am5.p50,
              text: "{valueX}",
              populateText: true,
              oversizedBehavior: "hide",
            }),
          });
        });

        series.data.setAll(dt);
        series.appear();

        return series;
      }

      var positiveColor = this.root.interfaceColors.get("positive");
      var negativeColor = this.root.interfaceColors.get("negative");

      createSeries(
        this.root,
        "Percent_Negative",
        "Negative",
        negativeColor,
        data
      );
      createSeries(
        this.root,
        "Percent_Positive",
        "Positive",
        positiveColor,
        data
      );

      let exporting = am5plugins_exporting.Exporting.new(this.root, {
        menu: am5plugins_exporting.ExportingMenu.new(this.root, {}),
        dataSource: data,
      });

      chart.appear(1000, 100);
    }
  }

  componentWillUnmount() {
    if (this.root) {
      this.root.dispose();
    }
  }

  render() {
    return (
      <div>
        <br></br>
        <div
          id="clustered"
          style={{ width: "90%", height: "750px", marginLeft: "2%" }}
        ></div>
      </div>
    );
  }
}

export default Clustered;
