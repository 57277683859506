import IDSNew from "./InterfaceComponents/IDSNew.js";

import React, { Component } from "react";
import { Container, Col, Row } from "react-bootstrap";

// Stylesheet
import "./App.css";

import { trackPromise } from "react-promise-tracker";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false,
      username: "",
      provider: "",
      password: "",
      errorMessage: false,
      show: false,
      userHash: Math.random().toString(36).substring(7),
    };
    this.logIn = this.logIn.bind(this);
    this.logOff = this.logOff.bind(this);
    this.close = this.close.bind(this);
    this.open = this.open.bind(this);
    this.passwordChange = this.passwordChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      this.logIn();
    }
  };

  logIn() {
    if (this.state.password !== "") {
      function getFields(input, field) {
        var output = [];
        for (var i = 0; i < input.length; ++i) output.push(input[i][field]);
        return output;
      }
      trackPromise(
        Promise.all([
          fetch(
            `https://ids-express-server.azurewebsites.net/credentials?password=` +
              this.state.password
          )
            .then((response) => response.json())
            .then((data) => {
              this.setState({
                provider: getFields(data.rowData.recordset, "Password")[0],
              });
              if (
                getFields(data.rowData.recordset, "Password")[0] &&
                getFields(data.rowData.recordset, "Password")[0] !== ""
              ) {
                this.setState({
                  password: getFields(
                    data.rowData.recordset,
                    "Company_Name"
                  )[0],
                });
                this.setState({ errorMessage: false });
                this.setState({ loggedIn: true });
              }
              if (!getFields(data.rowData.recordset, "Password")[0]) {
                this.setState({ errorMessage: true });
              }
            }),
        ])
      );
    }
  }

  logOff() {
    this.setState({ loggedIn: false });
  }

  componentWillUnmount() {
    this.setState({ loggedIn: false });
  }

  componenentDidUpdate(prevProps, prevState) {}

  close() {
    this.setState({ show: false });
  }
  open() {
    this.setState({ show: true });
  }

  passwordChange(event) {
    this.setState({ password: event.target.value });
  }
  handleSubmit(event) {
    event.preventDefault();
  }

  render() {
    if (this.state.loggedIn) {
      return (
        <div className="App">
          <IDSNew logOff={this.logOff} provider={this.state.password} />
        </div>
      );
    }
    return (
      <div className="Auth">
        <div>
          <Container
            fluid
            style={{ paddingLeft: 0, paddingRight: 0 }}
            id="auth-widget"
          >
            <Row style={{ marginLeft: 0, marginRight: 0 }}>
              <Col xs="8" style={{ paddingLeft: 0, paddingRight: 0 }}>
                <div className="auth_image2" style={{ padding: 0, margin: 0 }}>
                  <div>
                    <section class="cd-intro">
                      <div class="cd-intro-content mask-2">
                        <div class="content-wrapper">
                          <div>
                            <h1>IDS Portal</h1>
                            <p>Inclusion & Diversity in Your Organization</p>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </Col>
              <Col cs="4">
                <div className="auth-wrapper">
                  <div className="auth-inner">
                    <div
                      id="northStar"
                      style={{
                        height: "100",
                        marginLeft: "29%",
                        marginBottom: "4%",
                      }}
                    ></div>
                    <form>
                      <h3>Portal Login</h3>

                      {/* <div className="form-group">
                        <label>
                          <b>Email</b>
                        </label>
                        <input type="email" className="form-control" value="" />
                      </div> 
                      <br></br> */}
                      <div className="form-group">
                        <label>
                          <b>Password</b>
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          value={this.state.password}
                          onChange={this.passwordChange}
                          onKeyPress={this.handleKeyPress}
                        />
                      </div>
                      <br></br>
                    </form>
                    <button
                      type="submit"
                      className="logbtn"
                      style={{
                        height: "30",
                        marginBottom: "4%",
                      }}
                      onClick={() => this.logIn()}
                    >
                      <span>Login</span>
                    </button>
                    <div>
                      {this.state.errorMessage ? (
                        <div>
                          <p style={{ color: "firebrick", marginLeft: "10%" }}>
                            Incorrect password.{" "}
                            <a
                              style={{
                                color: "firebrick",
                              }}
                              href="mailto: benjamin.balough@bain.com"
                            >
                              Contact support.
                            </a>
                          </p>
                          <br></br>
                        </div>
                      ) : (
                        <div style={{ width: "100%", height: "0vh" }}></div>
                      )}
                    </div>
                    <p className="forgot-password text-right">
                      ©2023 Bain & Company
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default App;
