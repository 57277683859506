// Core libraries: React, React Bootstrap
import React, { Component } from "react";

// Tooltips & Icons
import ReactTooltip from "react-tooltip";

import makeAnimated from "react-select/animated";

import Treemap from "./Treemap";
import Clustered from "./Clustered";
import Ratio from "./Ratio";

import Select from "react-select";

// Icons
import { Icon } from "@rsuite/icons";
import { FaInfo, FaExpand, FaDownload } from "react-icons/fa";

import { Grid, Row, Col } from "rsuite";
import { Button, Panel, Drawer } from "rsuite";

// agGrid
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";

import { trackPromise } from "react-promise-tracker";

const animatedComponents = makeAnimated();

const sentimentOptions = [
  { value: "positive", label: "Positive" },
  { value: "neutral", label: "Neutral" },
  { value: "negative", label: "Negative" },
];

const layerOptions = [
  { value: "Layer_1", label: "Layer 1 Topics" },
  { value: "Layer_2", label: "Layer 2 Topics" },
];

class Verbatim extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modules: [ClientSideRowModelModule],

      showDrawer: false,
      showInfo: false,
      layerOne: "Layer_1",
      layerTwo: "Layer_1",
      chosenSentiment: "",
      whereClause: "",
      columnDefs: [
        {
          headerName: "Verbatim",
          field: "Verbatim",
          sortable: true,
          filter: true,
          floatingFilter: true,
          minWidth: 450,
          filter: "agTextColumnFilter",
          resizable: true,
          wrapText: true,
          autoHeight: true,
          columnGroupShow: "open",
          tooltipField: "Verbatim",
        },
        {
          headerName: "Minority",
          field: "minority",
          sortable: true,
          filter: false,
          maxWidth: 110,
          resizable: true,
          columnGroupShow: "open",
          tooltipField: "minority",
        },
      ],
      columnDefs2: [
        {
          headerName: "Verbatim",
          field: "Verbatim",
          sortable: true,
          filter: true,
          floatingFilter: true,
          minWidth: 1700,
          filter: "agTextColumnFilter",
          resizable: true,
          wrapText: true,
          autoHeight: true,
          columnGroupShow: "open",
          tooltipField: "Verbatim",
        },
        {
          headerName: "Minority",
          field: "minority",
          sortable: true,
          filter: false,
          maxWidth: 250,
          resizable: true,
          columnGroupShow: "open",
          tooltipField: "minority",
        },
      ],
      rowData: [],
    };
    this._onSentimentChange = this._onSentimentChange.bind(this);
    this._onLayer1Change = this._onLayer1Change.bind(this);
    this._onLayer2Change = this._onLayer2Change.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  refreshData() {
    let sentimentArray = [];
    if (this.state.chosenSentiment && this.state.chosenSentiment.length) {
      this.state.chosenSentiment.map((o) => sentimentArray.push(o.value));
    }

    trackPromise(
      Promise.all([
        fetch(
          `https://ids-express-server.azurewebsites.net/formatWhereClauseSmall?chosenSentiment=` +
            sentimentArray
        )
          .then((response) => response.text())
          .then((response) => {
            this.setState({
              whereClause: response,
            });
          })
          .catch((err) => console.log(err)),
      ])
    );
  }

  _onLayer1Change = (layerOne) => {
    this.setState({ layerOne });
  };
  _onLayer2Change = (layerTwo) => {
    this.setState({ layerTwo });
  };

  _onSentimentChange = (chosenSentiment) => {
    this.setState({ chosenSentiment });
  };

  handleChange(name, value) {
    this.setState({
      [name]: value,
    });
    console.log(name, value);
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    trackPromise(
      Promise.all([
        fetch(
          `https://ids-express-server.azurewebsites.net/verbatim_table?provider=` +
            this.props.provider
        )
          .then((response) => response.json())
          .then((data) => {
            this.setState({
              rowData: data.rowData.recordset,
            });
          }),
      ])
    );
  };

  onBtnExportDataAsCsv = () => {
    console.log("Triggered");
    var params = this.rowData;
    this.gridApi.exportDataAsCsv(params);
  };

  showDrawer() {
    this.setState({ showDrawer: true });
  }

  hidePanel() {
    this.setState({ showDrawer: false });
  }

  showInfo() {
    this.setState({ showInfo: true });
  }

  hideInfo() {
    this.setState({ showInfo: false });
  }
  componentDidMount() {
    trackPromise(
      Promise.all([
        fetch(
          `https://ids-express-server.azurewebsites.net/verbatim_table?provider=` +
            this.props.provider
        )
          .then((response) => response.json())
          .then((data) => {
            this.setState({
              rowData: data.rowData.recordset,
            });
          }),
      ])
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.chosenSentiment !== this.state.chosenSentiment) {
      this.refreshData();
    }
  }

  componentWillUnmount() {}

  render() {
    return (
      <div>
        <Panel header={<h3>IDS Drivers</h3>}>
          <Grid fluid>
            <Row className="show-grid">
              <Col xs={10}></Col>
              <Col xs={1}>
                <ReactTooltip id="infoTip" place="top" effect="solid">
                  <b>Information about this Data</b>
                </ReactTooltip>
                <ReactTooltip id="exportTip" place="top" effect="solid">
                  <b>Export Data (CSV)</b>
                </ReactTooltip>
                <ReactTooltip id="expandTip" place="top" effect="solid">
                  <b>View Verbatim Table Full Screen</b>
                </ReactTooltip>
                <Button
                  data-tip
                  data-for="infoTip"
                  className="shadowedButton"
                  style={{
                    backgroundColor: "white",
                    borderColor: "white",
                    marginLeft: "80%",
                    display: "inline-block",
                  }}
                  onClick={() => this.showInfo()}
                >
                  <Icon as={FaInfo} style={{ color: "black" }} size="1em" />
                </Button>
              </Col>
              <Col xs={1}>
                {" "}
                <Button
                  data-tip
                  className="shadowedButton"
                  data-for="exportTip"
                  id="exportData"
                  style={{
                    backgroundColor: "white",
                    borderColor: "white",
                    marginLeft: "80%",
                    display: "inline-block",
                  }}
                  onClick={() => this.onBtnExportDataAsCsv()}
                >
                  <Icon as={FaDownload} style={{ color: "black" }} size="1em" />
                </Button>
              </Col>
              <Col xs={1}>
                <Button
                  data-tip
                  data-for="expandTip"
                  className="shadowedButton"
                  style={{
                    backgroundColor: "white",
                    borderColor: "white",
                    marginLeft: "80%",
                    display: "inline-block",
                  }}
                  onClick={() => this.showDrawer()}
                >
                  <Icon as={FaExpand} style={{ color: "black" }} size="1em" />
                </Button>
              </Col>
            </Row>
          </Grid>
          <Grid fluid>
            <Row className="show-grid">
              <Col xs={16}>
                <br></br>
                <div
                  style={{
                    textAlign: "left",
                  }}
                >
                  <Panel
                    shaded
                    header={
                      <span>
                        <h5
                          style={{
                            padding: "0px",
                            display: "inline",
                          }}
                        >
                          Breakdown of Drivers by Topic
                        </h5>
                        <Icon
                          data-tip
                          data-for="infoTip2"
                          as={FaInfo}
                          style={{
                            color: "black",
                            marginLeft: "7px",
                            display: "inline",
                          }}
                          size="0.75em"
                        />
                      </span>
                    }
                  >
                    <ReactTooltip id="infoTip2" place="top" effect="solid">
                      <b>
                        Comments are categorized into layer 1 (broader) and
                        layer 2 (narrower) topics.
                      </b>
                    </ReactTooltip>
                    <ReactTooltip id="infoTip3" place="top" effect="solid">
                      <b>
                        Comments are categorized into layer 1 (broader) and
                        layer 2 (narrower) topics.
                      </b>
                    </ReactTooltip>
                    <ReactTooltip id="infoTip4" place="top" effect="solid">
                      <b>
                        Comments are categorized into layer 1 (broader) and
                        layer 2 (narrower) topics.
                      </b>
                    </ReactTooltip>
                    <Grid fluid>
                      <Row className="show-grid">
                        <Col xs={10}></Col>
                        <Col xs={4}>
                          <b
                            style={{
                              marginLeft: "20%",
                              fontSize: "11px",
                              marginTop: "5px",
                            }}
                          >
                            Filter by Sentiment
                          </b>
                          <div style={{ marginBottom: "10px" }}>
                            <Select
                              label=""
                              size="sm"
                              placement="topStart"
                              onChange={this._onSentimentChange}
                              isClearable={false}
                              isSearchable={false}
                              closeMenuOnSelect={false}
                              isMulti
                              components={animatedComponents}
                              value={this.state.chosenSentiment}
                              options={sentimentOptions}
                            />
                          </div>
                        </Col>
                        <Col xs={10}></Col>
                      </Row>
                    </Grid>
                    <Treemap
                      whereSmall={this.state.whereClause}
                      provider={this.props.provider}
                    />
                  </Panel>
                  <br></br>
                  <Panel
                    shaded
                    header={
                      <span>
                        <h5
                          style={{
                            padding: "0px",
                            display: "inline",
                          }}
                        >
                          Percentage of Positive to Negative Comments by Topic{" "}
                        </h5>
                        <Icon
                          data-tip
                          data-for="infoTip3"
                          as={FaInfo}
                          style={{
                            color: "black",
                            marginLeft: "7px",
                            display: "inline",
                          }}
                          size="0.75em"
                        />
                      </span>
                    }
                  >
                    {" "}
                    <Grid fluid>
                      <Row className="show-grid">
                        <Col xs={10}></Col>
                        <Col xs={4}>
                          <b
                            style={{
                              marginLeft: "20%",
                              fontSize: "11px",
                              marginTop: "5px",
                            }}
                          >
                            Select Layer to Display
                          </b>
                          <div style={{ marginBottom: "10px" }}>
                            <Select
                              label=""
                              size="sm"
                              placement="topStart"
                              onChange={this._onLayer1Change}
                              isClearable={false}
                              isSearchable={false}
                              closeMenuOnSelect={false}
                              components={animatedComponents}
                              value={this.state.layerOne}
                              options={layerOptions}
                            />
                          </div>
                        </Col>
                        <Col xs={10}></Col>
                      </Row>
                    </Grid>
                    <Clustered
                      provider={this.props.provider}
                      layer={this.state.layerOne}
                    />
                  </Panel>
                  <br></br>
                  <Panel
                    shaded
                    header={
                      <span>
                        <h5
                          style={{
                            padding: "0px",
                            display: "inline",
                          }}
                        >
                          Ratio of Positive to Negative Comments vs. Industry
                        </h5>
                        <Icon
                          data-tip
                          data-for="infoTip4"
                          as={FaInfo}
                          style={{
                            color: "black",
                            marginLeft: "7px",
                            display: "inline",
                          }}
                          size="0.75em"
                        />
                      </span>
                    }
                  >
                    <Grid fluid>
                      <Row className="show-grid">
                        <Col xs={10}></Col>
                        <Col xs={4}>
                          <b
                            style={{
                              marginLeft: "20%",
                              fontSize: "11px",
                              marginTop: "5px",
                            }}
                          >
                            Select Layer to Display
                          </b>
                          <div style={{ marginBottom: "10px" }}>
                            <Select
                              label=""
                              size="sm"
                              placement="topStart"
                              onChange={this._onLayer2Change}
                              isClearable={false}
                              isSearchable={false}
                              closeMenuOnSelect={false}
                              components={animatedComponents}
                              value={this.state.layerTwo}
                              options={layerOptions}
                            />
                          </div>
                        </Col>
                        <Col xs={10}></Col>
                      </Row>
                    </Grid>
                    <Ratio
                      provider={this.props.provider}
                      layer={this.state.layerTwo}
                    />
                  </Panel>
                </div>
              </Col>
              <Col xs={8}>
                <div>
                  <br></br>
                  <Panel shaded>
                    <div
                      id="gridVerb"
                      className="ag-theme-material"
                      style={{
                        marginLeft: "0%",
                        width: "100%",
                        opacity: 1,
                        height: "281vh",
                        overflowX: "hidden",
                      }}
                    >
                      <AgGridReact
                        pagination={true}
                        headerHeight={50}
                        autoHeight={true}
                        groupHeaderHeight={15}
                        resizable={true}
                        tooltipShowDelay={false}
                        columnDefs={this.state.columnDefs}
                        rowData={this.state.rowData}
                        animateRows={true}
                        debounceVerticalScrollbar={true}
                      ></AgGridReact>
                    </div>
                  </Panel>

                  <br></br>

                  <Drawer
                    backdrop={true}
                    size="full"
                    placement="bottom"
                    open={this.state.showDrawer}
                    onClose={() => this.hidePanel()}
                  >
                    <Drawer.Header>
                      <Drawer.Title>Verbatims</Drawer.Title>
                    </Drawer.Header>
                    <Drawer.Body>
                      <Panel shaded style={{ marginTop: "5px" }}>
                        <div
                          id="gridVerb2"
                          className="ag-theme-material"
                          style={{
                            marginLeft: "0%",
                            width: "100%",
                            opacity: 1,
                            height: "74vh",
                            overflowX: "hidden",
                          }}
                        >
                          <AgGridReact
                            pagination={true}
                            headerHeight={50}
                            autoHeight={true}
                            groupHeaderHeight={15}
                            resizable={true}
                            tooltipShowDelay={false}
                            columnDefs={this.state.columnDefs2}
                            rowData={this.state.rowData}
                            animateRows={true}
                            debounceVerticalScrollbar={true}
                            onGridReady={this.onGridReady}
                          ></AgGridReact>
                        </div>
                      </Panel>
                    </Drawer.Body>
                  </Drawer>
                  <Drawer
                    backdrop={true}
                    size="full"
                    placement="bottom"
                    open={this.state.showInfo}
                    onClose={() => this.hideInfo()}
                  >
                    <Drawer.Header>
                      <Drawer.Title>Information</Drawer.Title>
                    </Drawer.Header>
                    <Drawer.Body>
                      <div className="ppt"></div>
                    </Drawer.Body>
                  </Drawer>
                </div>
              </Col>
            </Row>
          </Grid>
        </Panel>
      </div>
    );
  }
}

export default Verbatim;
