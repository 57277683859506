// Core libraries: React, React Bootstrap
import React, { Component } from "react";

// Core UI Library: RSuite
import "rsuite/dist/rsuite.min.css";
import { Col, Panel, FlexboxGrid } from "rsuite";

// Interface Components
import Scorecard from "../Charts/scorecard.js";
import Scorecard2 from "../Charts/scorecard2.js";
import Gauge from "../Charts/gauge.js";
import Loader from "../InterfaceComponents/Loader";

import { trackPromise } from "react-promise-tracker";

class IDSReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      treeData: [],
      treeData2: [],
      treeData3: [],
      treeData4: [],
      inclusionYou: 0,
      diversityYou: 0,
      idsYou: 0,
      inclusionThem: 0,
      diversityThem: 0,
      idsThem: 0,
    };
    this.refreshIDS = this.refreshIDS.bind(this);
  }

  refreshIDS() {
    this.setState({ loading: true });
    trackPromise(
      Promise.all([
        fetch(
          `https://ids-express-server.azurewebsites.net/ids_you?provider=` +
            this.props.provider +
            `&whereClause=` +
            this.props.whereClause
        )
          .then((response) => response.json())
          .then((data) => {
            function getFields(input, field) {
              var output = [];
              for (var i = 0; i < input.length; ++i)
                output.push(input[i][field]);
              return output;
            }
            this.setState({
              treeData: data.rowData.recordset,
              treeData2: data.rowData2.recordset,
              idsYou:
                getFields(data.rowData.recordset, "Inclusion_Score")[0] +
                getFields(data.rowData2.recordset, "Diversity_Score")[0],
              inclusionYou: getFields(
                data.rowData.recordset,
                "Inclusion_Score"
              )[0],
              diversityYou: getFields(
                data.rowData2.recordset,
                "Diversity_Score"
              )[0],
            });
          }),
      ])
    );
    trackPromise(
      Promise.all([
        fetch(
          `https://ids-express-server.azurewebsites.net/ids_them?provider=` +
            this.props.provider +
            `&whereClause=` +
            this.props.whereClause
        )
          .then((response) => response.json())
          .then((data) => {
            function getFields(input, field) {
              var output = [];
              for (var i = 0; i < input.length; ++i)
                output.push(input[i][field]);
              return output;
            }
            this.setState({
              treeData3: data.rowData.recordset,
              treeData4: data.rowData2.recordset,
              inclusionThem: getFields(
                data.rowData.recordset,
                "Inclusion_Score"
              )[0],
              diversityThem: getFields(
                data.rowData2.recordset,
                "Diversity_Score"
              )[0],
              idsThem:
                getFields(data.rowData.recordset, "Inclusion_Score")[0] +
                getFields(data.rowData2.recordset, "Diversity_Score")[0],
            });
          }),
      ])
    );
    this.setState({ loading: false });
  }

  async componentDidMount() {
    this.refreshIDS();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.whereClause !== this.props.whereClause) {
      this.refreshIDS();
      console.log(this.props.whereClause);
    }
  }

  render() {
    if (!this.state.treeData || this.state.treeData.length === 0) {
      return <Loader></Loader>;
    }

    return (
      <div>
        <div>
          <Panel header={<h3>Your IDS Report</h3>}>
            <br></br>

            <FlexboxGrid justify="space-around">
              <FlexboxGrid.Item as={Col} colspan={24} md={6} smHidden>
                {this.state.treeData && this.state.treeData.length > 0 ? (
                  <Gauge
                    whereClause={this.state.whereClause}
                    provider={this.props.provider}
                    idsYou={this.state.idsYou}
                    idsThem={this.state.idsThem}
                    inclusionYou={this.state.inclusionYou}
                    inclusionThem={this.state.inclusionThem}
                    diversityYou={this.state.diversityYou}
                    diversityThem={this.state.diversityThem}
                  />
                ) : null}
              </FlexboxGrid.Item>

              <FlexboxGrid.Item as={Col} colspan={24} md={6} smHidden>
                {this.state.treeData && this.state.treeData.length > 0 ? (
                  <Scorecard
                    whereClause={this.state.whereClause}
                    provider={this.props.provider}
                    idsYou={this.state.idsYou}
                    idsThem={this.state.idsThem}
                    inclusionYou={this.state.inclusionYou}
                    inclusionThem={this.state.inclusionThem}
                    diversityYou={this.state.diversityYou}
                    diversityThem={this.state.diversityThem}
                  />
                ) : null}
              </FlexboxGrid.Item>
              <FlexboxGrid.Item as={Col} colspan={24} md={6} smHidden>
                {this.state.treeData && this.state.treeData.length > 0 ? (
                  <Scorecard2
                    whereClause={this.state.whereClause}
                    provider={this.props.provider}
                    idsYou={this.state.idsYou}
                    idsThem={this.state.idsThem}
                    inclusionYou={this.state.inclusionYou}
                    inclusionThem={this.state.inclusionThem}
                    diversityYou={this.state.diversityYou}
                    diversityThem={this.state.diversityThem}
                  />
                ) : null}
              </FlexboxGrid.Item>
            </FlexboxGrid>
            <div className="show-grid"></div>
            <br></br>
          </Panel>
        </div>
      </div>
    );
  }
}

export default IDSReport;
