import React, { Component } from "react";
import { Grid, Row } from "rsuite";

import Bain from "../Images/bain_logo.png";
import TM from "../Images/tmforum_logo.png";

class Logout extends Component {
  render() {
    return (
      <div
        style={{
          width: "100%",
          height: "95vh",
          maxHeight: "75vh",
          position: "absolute",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid fluid>
          <Row className="show-grid" style={{ textAlign: "center" }}>
            {" "}
            <img
              src={Bain}
              style={{ marginBottom: "5vh", height: "3vh" }}
            ></img>
          </Row>
          <Row className="show-grid" style={{ textAlign: "center" }}>
            {" "}
            <h4>Logged out. Thank you for visiting the IDS Portal.</h4>
          </Row>
          <Row className="show-grid" style={{ textAlign: "center" }}>
            {" "}
            <img src={TM} style={{ marginTop: "5vh", height: "3vh" }}></img>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Logout;
