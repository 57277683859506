import React, { Component } from "react";
import PuffLoader from "react-spinners/PuffLoader";

class Loader extends Component {
  render() {
    return (
      <div
        style={{
          width: "100%",
          height: "95vh",
          maxHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <PuffLoader size={500} color={"navy"} />
      </div>
    );
  }
}

export default Loader;
