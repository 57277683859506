// Core libraries: React, React Bootstrap
import React, { Component } from "react";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

import MakeComparisonSlim from "../InterfaceComponents/MakeComparisonSlim";

import { trackPromise } from "react-promise-tracker";

class DemoChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      treeData: [],
      you: 0,
      them: 0,
      root: null,
      femaleCountry: 0,
      lqbtgCountry: 0,
      minorityCountry: 0,
    };
    this.makechart = this.makechart.bind(this);
    this.fetchdata = this.fetchdata.bind(this);
  }

  makechart() {
    this.root.setThemes([am5themes_Animated.new(this.root)]);

    var chart = this.root.container.children.push(
      am5xy.XYChart.new(this.root, {
        panX: false,
        panY: false,
        layout: this.root.verticalLayout,
      })
    );

    var xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(this.root, {
        categoryField: "COL",
        renderer: am5xy.AxisRendererX.new(this.root, {}),
        tooltip: am5.Tooltip.new(this.root, {}),
      })
    );

    xAxis.data.setAll(this.state.treeData);

    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(this.root, {
        min: 0,
        max: 100,
        numberFormat: "#'%'",
        strictMinMax: true,
        calculateTotals: true,
        renderer: am5xy.AxisRendererY.new(this.root, {}),
      })
    );

    yAxis.get("renderer").grid.template.set("forceHidden", true);

    function makeSeries(rt, name, fieldName, dt) {
      var series = chart.series.push(
        am5xy.ColumnSeries.new(rt, {
          name: name,
          stacked: true,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: fieldName,
          valueYShow: "valueY",
          categoryXField: "COL",
        })
      );

      series.columns.template.setAll({
        tooltipText:
          "{name}, {categoryX}:  {valueYTotalPercent.formatNumber('#.#')}%",
        tooltipY: am5.percent(10),
      });
      series.data.setAll(dt);
      series.appear();

      series.bullets.push(function () {
        return am5.Bullet.new(rt, {
          sprite: am5.Label.new(rt, {
            text: "{name}:  {valueYTotalPercent.formatNumber('#.#')}%",
            fill: rt.interfaceColors.get("alternativeText"),
            centerY: am5.p50,
            centerX: am5.p50,
            populateText: true,
          }),
        });
      });
    }

    if (this.props.query === "gender") {
      makeSeries(this.root, "Male", "Male", this.state.treeData);
      makeSeries(this.root, "Female", "Female", this.state.treeData);
      makeSeries(this.root, "Other", "Other", this.state.treeData);
      makeSeries(
        this.root,
        "No Reply",
        "Do not wish to reply",
        this.state.treeData
      );
    }

    if (this.props.query === "demo_minority") {
      makeSeries(this.root, "Minority", "Minority", this.state.treeData);
      makeSeries(this.root, "Majority", "Majority", this.state.treeData);
    }

    if (this.props.query === "demo_orientation") {
      makeSeries(this.root, "Straight", "Straight", this.state.treeData);
      makeSeries(this.root, "LGBTQ+", "LGBTQ", this.state.treeData);
      makeSeries(this.root, "No reply", "No reply", this.state.treeData);
    }

    if (this.props.query === "demo_age") {
      makeSeries(this.root, "15-20", "15-20", this.state.treeData);
      makeSeries(this.root, "21-35", "21-35", this.state.treeData);
      makeSeries(this.root, "36-50", "36-50", this.state.treeData);
      makeSeries(this.root, "51-60", "51-60", this.state.treeData);
      makeSeries(this.root, "61-70", "61-70", this.state.treeData);
      makeSeries(this.root, "70+", "70+", this.state.treeData);
    }

    if (this.props.query === "demo_disability") {
      makeSeries(this.root, "No", "No", this.state.treeData);
      makeSeries(this.root, "Yes", "Yes", this.state.treeData);
      makeSeries(this.root, "No Reply", "No Reply", this.state.treeData);
    }

    if (this.props.query === "demo_minority_2") {
      makeSeries(this.root, "No", "No", this.state.treeData);
      makeSeries(this.root, "Yes", "Yes", this.state.treeData);
      makeSeries(this.root, "No Reply", "No Reply", this.state.treeData);
    }

    chart.appear(1000, 100);
  }

  fetchdata() {
    trackPromise(
      Promise.all([
        fetch(
          `https://ids-express-server.azurewebsites.net/` +
            this.props.query +
            `?provider=` +
            this.props.provider +
            `&whereClause=` +
            this.props.whereClause
        )
          .then((response) => response.json())
          .then((data) => {
            this.setState({
              treeData: data.rowData.recordset,
            });
            function getFields(input, field) {
              var output = [];
              for (var i = 0; i < input.length; ++i)
                output.push(input[i][field]);
              return output;
            }
            if (this.props.query === "gender") {
              this.setState({
                you:
                  getFields(data.rowData.recordset, "Female")[0] +
                  getFields(data.rowData.recordset, "Other")[0],
                them:
                  getFields(data.rowData.recordset, "Female")[1] +
                  getFields(data.rowData.recordset, "Other")[1],
                femaleCountry: getFields(data.rowData.recordset, "Female")[2],
              });
            }

            if (this.props.query === "demo_minority") {
              this.setState({
                you: getFields(data.rowData.recordset, "Minority")[0],
                them: getFields(data.rowData.recordset, "Minority")[1],
              });
            }

            if (this.props.query === "demo_minority_2") {
              this.setState({
                you: getFields(data.rowData.recordset, "Yes")[0],
                them: getFields(data.rowData.recordset, "Yes")[1],
                minorityCountry: getFields(data.rowData.recordset, "Yes")[2],
              });
            }

            if (this.props.query === "demo_orientation") {
              this.setState({
                you: getFields(data.rowData.recordset, "LGBTQ")[0],
                them: getFields(data.rowData.recordset, "LGBTQ")[1],
                lqbtgCountry: getFields(data.rowData.recordset, "LGBTQ")[2],
              });
            }

            if (this.props.query === "demo_age") {
            }

            if (this.props.query === "demo_disability") {
            }
          }),
      ])
    );
  }

  componentDidMount() {
    function getFields(input, field) {
      var output = [];
      for (var i = 0; i < input.length; ++i) output.push(input[i][field]);
      return output;
    }

    this.fetchdata();
    var root = am5.Root.new(this.props.chartID);
    this.root = root;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.treeData.length >= 1 &&
      prevState.treeData.length !== this.state.treeData
    ) {
      this.makechart();
    }

    if (prevProps.whereClause !== this.props.whereClause) {
      this.root.dispose();
      this.fetchdata();
      var root = am5.Root.new(this.props.chartID);
      this.root = root;
      this.makechart();
    }
  }

  componentWillUnmount() {
    if (this.root) {
      this.root.dispose();
    }
  }

  render() {
    return (
      <div>
        <br></br>
        <div
          id={this.props.chartID}
          style={{ width: "100%", height: "450px" }}
        ></div>
        <br></br>
        {this.props.query === "gender" ? (
          <div>
            <br></br>

            <p>
              {this.state.treeData && this.state.treeData.length > 0 ? (
                <MakeComparisonSlim
                  you={this.state.you}
                  them={this.state.femaleCountry}
                  descrip={this.props.descrip}
                  descrip2="national"
                />
              ) : null}
            </p>
          </div>
        ) : null}
        {this.props.query === "demo_minority_2" ? (
          <div>
            <br></br>

            <p>
              {this.state.treeData && this.state.treeData.length > 0 ? (
                <MakeComparisonSlim
                  you={this.state.you}
                  them={this.state.minorityCountry}
                  descrip={this.props.descrip}
                  descrip2="national"
                />
              ) : null}
            </p>
          </div>
        ) : null}
        {this.props.query === "demo_orientation" ? (
          <div>
            <br></br>

            <p>
              {this.state.treeData && this.state.treeData.length > 0 ? (
                <MakeComparisonSlim
                  you={this.state.you}
                  them={this.state.lgbtqCountry}
                  descrip={this.props.descrip}
                  descrip2="national"
                />
              ) : null}
            </p>
          </div>
        ) : null}
        <p>
          {this.state.treeData && this.state.treeData.length > 0 ? (
            <MakeComparisonSlim
              you={this.state.you}
              them={this.state.them}
              descrip={this.props.descrip}
              descrip2={this.props.descrip2}
            />
          ) : null}
        </p>
      </div>
    );
  }
}

export default DemoChart;
