// Core libraries: React, React Bootstrap
import React, { Component } from "react";

import { Grid, Row, Col } from "rsuite";
import { Panel, PanelGroup, Divider } from "rsuite";

// Loading Components

// Tooltips & Icons
import ReactTooltip from "react-tooltip";

import InclusionChart from "./InclusionChart";
import MakeArrow from "../InterfaceComponents/MakeArrow";

import { trackPromise } from "react-promise-tracker";

class Inclusion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstData: [],
      secondData: [],
      thirdData: [],
      fourthData: [],
      fifthData: [],
      sixData: [],
      seventyData: [],
      cols1: ["Column", "Column", "Column"],
      you1: [0, 0, 0],
      they1: [0, 0, 0],
      n1: [0, 0, 0],
      diff1: [0, 0, 0],
      cols2: ["Column", "Column", "Column"],
      you2: [0, 0, 0],
      they2: [0, 0, 0],
      n2: [0, 0, 0],
      diff2: [0, 0, 0],
      cols3: ["Column", "Column", "Column"],
      you3: [0, 0, 0],
      they3: [0, 0, 0],
      n3: [0, 0, 0],
      diff3: [0, 0, 0],
      cols4: ["Column", "Column", "Column"],
      you4: [0, 0, 0],
      they4: [0, 0, 0],
      n4: [0, 0, 0],
      diff4: [0, 0, 0],
      cols5: ["Column", "Column", "Column"],
      you5: [0, 0, 0],
      they5: [0, 0, 0],
      n5: [0, 0, 0],
      diff5: [0, 0, 0],
      cols6: ["Column", "Column", "Column"],
      you6: [0, 0, 0],
      they6: [0, 0, 0],
      n6: [0, 0, 0],
      diff6: [0, 0, 0],
      cols7: ["Column", "Column", "Column"],
      you7: [0, 0, 0],
      they7: [0, 0, 0],
      n7: [0, 0, 0],
      diff7: [0, 0, 0],
      cols70: ["Column", "Column", "Column"],
      you70: [0, 0, 0],
      they70: [0, 0, 0],
      n70: [0, 0, 0],
      diff70: [0, 0, 0],
    };
    this.refresh = this.refresh.bind(this);
  }

  refresh() {
    function getFields(input, field) {
      var output = [];
      for (var i = 0; i < input.length; ++i) output.push(input[i][field]);
      return output;
    }

    trackPromise(
      Promise.all([
        fetch(
          `https://ids-express-server.azurewebsites.net/inclusion_minority_table?provider=` +
            this.props.provider +
            `&whereClause=` +
            this.props.whereClause
        )
          .then((response) => response.json())
          .then((data) => {
            this.setState({
              firstData: data.rowData.recordset,
            });
            this.setState({
              cols1: getFields(data.rowData.recordset, "Type"),
            });
            this.setState({
              you1: getFields(data.rowData.recordset, "You_Inclusion"),
            });
            this.setState({
              they1: getFields(data.rowData.recordset, "They_Inclusion"),
            });
            this.setState({
              n1: getFields(data.rowData.recordset, "You_N"),
            });
            this.setState({
              diff1: getFields(data.rowData.recordset, "Difference"),
            });
          }),
      ])
    );
    trackPromise(
      Promise.all([
        fetch(
          `https://ids-express-server.azurewebsites.net/inclusion_gender_table?provider=` +
            this.props.provider +
            `&whereClause=` +
            this.props.whereClause
        )
          .then((response) => response.json())
          .then((data) => {
            this.setState({
              secondData: data.rowData.recordset,
            });
            this.setState({
              cols2: getFields(data.rowData.recordset, "Type"),
            });
            this.setState({
              you2: getFields(data.rowData.recordset, "You_Inclusion"),
            });
            this.setState({
              they2: getFields(data.rowData.recordset, "They_Inclusion"),
            });
            this.setState({
              n2: getFields(data.rowData.recordset, "You_N"),
            });
            this.setState({
              diff2: getFields(data.rowData.recordset, "Difference"),
            });
          }),
      ])
    );
    trackPromise(
      Promise.all([
        fetch(
          `https://ids-express-server.azurewebsites.net/inclusion_age_table?provider=` +
            this.props.provider +
            `&whereClause=` +
            this.props.whereClause
        )
          .then((response) => response.json())
          .then((data) => {
            this.setState({
              thirdData: data.rowData.recordset,
            });
            this.setState({
              cols3: getFields(data.rowData.recordset, "Type"),
            });
            this.setState({
              you3: getFields(data.rowData.recordset, "You_Inclusion"),
            });
            this.setState({
              they3: getFields(data.rowData.recordset, "They_Inclusion"),
            });
            this.setState({
              n3: getFields(data.rowData.recordset, "You_N"),
            });
            this.setState({
              diff3: getFields(data.rowData.recordset, "Difference"),
            });
          }),
      ])
    );
    trackPromise(
      Promise.all([
        fetch(
          `https://ids-express-server.azurewebsites.net/inclusion_orientation_table?provider=` +
            this.props.provider +
            `&whereClause=` +
            this.props.whereClause
        )
          .then((response) => response.json())
          .then((data) => {
            this.setState({
              fourthData: data.rowData.recordset,
            });
            this.setState({
              cols4: getFields(data.rowData.recordset, "Type"),
            });
            this.setState({
              you4: getFields(data.rowData.recordset, "You_Inclusion"),
            });
            this.setState({
              they4: getFields(data.rowData.recordset, "They_Inclusion"),
            });
            this.setState({
              n4: getFields(data.rowData.recordset, "You_N"),
            });
            this.setState({
              diff4: getFields(data.rowData.recordset, "Difference"),
            });
          }),
      ])
    );
    trackPromise(
      Promise.all([
        fetch(
          `https://ids-express-server.azurewebsites.net/inclusion_leadership_table?provider=` +
            this.props.provider +
            `&whereClause=` +
            this.props.whereClause
        )
          .then((response) => response.json())
          .then((data) => {
            this.setState({
              fifthData: data.rowData.recordset,
            });
            this.setState({
              cols5: getFields(data.rowData.recordset, "Type"),
            });
            this.setState({
              you5: getFields(data.rowData.recordset, "You_Inclusion"),
            });
            this.setState({
              they5: getFields(data.rowData.recordset, "They_Inclusion"),
            });
            this.setState({
              n5: getFields(data.rowData.recordset, "You_N"),
            });
            this.setState({
              diff5: getFields(data.rowData.recordset, "Difference"),
            });
          }),
      ])
    );
    trackPromise(
      Promise.all([
        fetch(
          `https://ids-express-server.azurewebsites.net/inclusion_disability_table?provider=` +
            this.props.provider +
            `&whereClause=` +
            this.props.whereClause
        )
          .then((response) => response.json())
          .then((data) => {
            this.setState({
              sixData: data.rowData.recordset,
            });
            this.setState({
              cols6: getFields(data.rowData.recordset, "Type"),
            });
            this.setState({
              you6: getFields(data.rowData.recordset, "You_Inclusion"),
            });
            this.setState({
              they6: getFields(data.rowData.recordset, "They_Inclusion"),
            });
            this.setState({
              n6: getFields(data.rowData.recordset, "You_N"),
            });
            this.setState({
              diff6: getFields(data.rowData.recordset, "Difference"),
            });
          }),
      ])
    );
    trackPromise(
      Promise.all([
        fetch(
          `https://ids-express-server.azurewebsites.net/inclusion_remote_table?provider=` +
            this.props.provider +
            `&whereClause=` +
            this.props.whereClause
        )
          .then((response) => response.json())
          .then((data) => {
            this.setState({
              seventyData: data.rowData.recordset,
            });
            this.setState({
              cols70: getFields(data.rowData.recordset, "Type"),
            });
            this.setState({
              you70: getFields(data.rowData.recordset, "You_Inclusion"),
            });
            this.setState({
              they70: getFields(data.rowData.recordset, "They_Inclusion"),
            });
            this.setState({
              n70: getFields(data.rowData.recordset, "You_N"),
            });
            this.setState({
              diff70: getFields(data.rowData.recordset, "Difference"),
            });
          }),
      ])
    );
  }

  componentDidMount() {
    this.refresh();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.firstData.length >= 1 &&
      prevState.firstData !== this.state.firstData
    ) {
      this.refresh();
    }

    if (prevProps.whereClause !== this.props.whereClause) {
      this.refresh();
    }
  }

  componentWillUnmount() {}

  render() {
    return (
      <div>
        <br></br>
        <h3>Your Inclusion Report</h3>
        <br></br>
        <span>
          <b style={{ display: "inline" }}>Definition of Inclusion</b>
          <i style={{ display: "inline" }}>
            : I feel a sense of belonging in my organization and I am treated
            fairly. I have an equal opportunity to succeed and I am encouraged
            to bring my whole self to work.
          </i>
        </span>
        <i></i>
        <br></br>
        <br></br>

        <PanelGroup
          accordion
          bordered
          style={{
            minWidth: "60vh",
            marginLeft: "15vh",
            marginRight: "15vh",
            textAlign: "left",
          }}
        >
          <Panel
            className="shadowed-panel"
            header={
              <h5 style={{ padding: "4px" }}>By Racial/Ethnic Minority</h5>
            }
            eventKey={1}
            id="panel1"
          >
            <Grid fluid>
              <Row className="show-grid" style={{ textAlign: "center" }}>
                <Divider>
                  Would you consider your race or ethnicity a minority in your
                  country?
                </Divider>
                <Col xs={12}>
                  <h5>Internal Inclusion Score</h5>
                  <InclusionChart
                    provider={this.props.provider}
                    whereClause={this.props.whereClause}
                    chartID={"chartOne"}
                    query={"inclusion_minority"}
                  />
                </Col>
                <Col xs="12">
                  <h5>External Comparison</h5>
                  <br></br>
                  <Grid fluid>
                    <Row className="show-grid" style={{ textAlign: "center" }}>
                      <Col xs={10}> </Col>

                      <Col xs={4}>
                        <h5>{this.state.cols1[0]}</h5>
                      </Col>
                      <Col xs={4}>
                        <h5>{this.state.cols1[1]}</h5>
                      </Col>
                      <Col xs={4}>
                        <h5>{this.state.cols1[2]}</h5>
                      </Col>
                    </Row>
                    <Divider
                      style={{ maxWidth: "50vh", marginLeft: "15vh" }}
                    ></Divider>

                    <Row className="show-grid" style={{ textAlign: "center" }}>
                      <Col xs={6}> </Col>
                      <Col xs={4}>
                        {" "}
                        <h5>You</h5>
                      </Col>
                      <Col xs={4}>
                        {" "}
                        <h5 style={{ color: "black" }}>
                          {" "}
                          {this.state.n1[0] >= 20 ? this.state.you1[0] : "-"}
                        </h5>
                      </Col>
                      <Col xs={4}>
                        {" "}
                        <h5 style={{ color: "black" }}>
                          {" "}
                          {this.state.n1[1] >= 20 ? this.state.you1[1] : "-"}
                        </h5>
                      </Col>
                      <Col xs={4}>
                        {" "}
                        <h5 style={{ color: "black" }}>
                          {" "}
                          {this.state.n1[2] >= 20 ? this.state.you1[2] : "-"}
                        </h5>
                      </Col>
                    </Row>
                    <br></br>
                    <Row className="show-grid" style={{ textAlign: "center" }}>
                      <Col xs={6}> </Col>

                      <Col xs={4}>
                        <h5>Industry</h5>
                      </Col>
                      <Col xs={4}>
                        {" "}
                        <h5 style={{ color: "black" }}>
                          {" "}
                          {this.state.they1[0]}
                        </h5>
                      </Col>
                      <Col xs={4}>
                        {" "}
                        <h5 style={{ color: "black" }}>
                          {" "}
                          {this.state.they1[1]}
                        </h5>
                      </Col>
                      <Col xs={4}>
                        {" "}
                        <h5 style={{ color: "black" }}>
                          {" "}
                          {this.state.they1[2]}
                        </h5>
                      </Col>
                    </Row>
                    <br></br>
                    <Row className="show-grid" style={{ textAlign: "center" }}>
                      <Col xs={6}> </Col>

                      <Col xs={4}>
                        <h5>+/-</h5>
                      </Col>
                      <Col xs={4}>
                        {" "}
                        <p>
                          {this.state.n1[0] >= 20 ? (
                            <p>
                              <MakeArrow arrow={this.state.diff1[0]} />
                              <b> {this.state.diff1[0]}</b>
                            </p>
                          ) : (
                            "-"
                          )}
                        </p>
                      </Col>
                      <Col xs={4}>
                        <p>
                          {this.state.n1[1] >= 20 ? (
                            <p>
                              <MakeArrow arrow={this.state.diff1[1]} />
                              <b> {this.state.diff1[1]}</b>
                            </p>
                          ) : (
                            "-"
                          )}
                        </p>
                      </Col>
                      <Col xs={4}>
                        <p>
                          {this.state.n1[2] >= 20 ? (
                            <p>
                              <MakeArrow arrow={this.state.diff1[2]} />
                              <b> {this.state.diff1[2]}</b>
                            </p>
                          ) : (
                            "-"
                          )}
                        </p>
                      </Col>
                    </Row>
                    <Divider
                      style={{ maxWidth: "50vh", marginLeft: "15vh" }}
                    ></Divider>
                    <Row className="show-grid" style={{ textAlign: "center" }}>
                      <Col xs={6}> </Col>

                      <Col xs={4}>
                        <h5>n</h5>
                      </Col>
                      <Col xs={4}>
                        {" "}
                        <p>
                          <b>({this.state.n1[0]})</b>
                        </p>
                      </Col>
                      <Col xs={4}>
                        <p>
                          <b>({this.state.n1[1]})</b>
                        </p>
                      </Col>
                      <Col xs={4}>
                        <p>
                          <b>({this.state.n1[2]})</b>
                        </p>
                      </Col>
                    </Row>
                  </Grid>
                </Col>
              </Row>
            </Grid>
          </Panel>
          <Panel
            className="shadowed-panel"
            header={<h5 style={{ padding: "4px" }}>By Gender</h5>}
            eventKey={1}
            id="panel1"
          >
            <Grid fluid>
              <Row className="show-grid" style={{ textAlign: "center" }}>
                <hr></hr>
                <Col xs={12}>
                  <h5>Internal Inclusion Score</h5>
                  <InclusionChart
                    provider={this.props.provider}
                    whereClause={this.props.whereClause}
                    chartID={"chartTwo"}
                    query={"inclusion_gender"}
                  />
                </Col>
                <Col xs="12">
                  <h5>External Comparison</h5>
                  <br></br>
                  <Grid fluid>
                    <Row className="show-grid" style={{ textAlign: "center" }}>
                      <Col xs={6}> </Col>

                      <Col xs={4}>
                        <h5>{this.state.cols2[0]}</h5>
                      </Col>
                      <Col xs={4}>
                        <h5>{this.state.cols2[1]}</h5>
                      </Col>
                      <Col xs={4}>
                        <ReactTooltip id="unkTop" place="top" effect="solid">
                          <b>
                            Undisclosed respondents either opted to not reply or
                            answered an unknown answer.
                          </b>
                        </ReactTooltip>
                        <h5 data-tip data-for="unkTop">
                          {this.state.cols2[2]}
                        </h5>
                      </Col>
                      <Col xs={4}>
                        <h5>{this.state.cols2[3]}</h5>
                      </Col>
                    </Row>
                    <Divider
                      style={{ maxWidth: "50vh", marginLeft: "15vh" }}
                    ></Divider>

                    <Row className="show-grid" style={{ textAlign: "center" }}>
                      <Col xs={2}> </Col>
                      <Col xs={4}>
                        {" "}
                        <h5>You</h5>
                      </Col>
                      <Col xs={4}>
                        {" "}
                        <h5 style={{ color: "black" }}>
                          {" "}
                          {this.state.n2[0] >= 20 ? this.state.you2[0] : "-"}
                        </h5>
                      </Col>
                      <Col xs={4}>
                        {" "}
                        <h5 style={{ color: "black" }}>
                          {" "}
                          {this.state.n2[1] >= 20 ? this.state.you2[1] : "-"}
                        </h5>
                      </Col>
                      <Col xs={4}>
                        {" "}
                        <h5 style={{ color: "black" }}>
                          {" "}
                          {this.state.n2[2] >= 20 ? this.state.you2[2] : "-"}
                        </h5>
                      </Col>
                      <Col xs={4}>
                        {" "}
                        <h5 style={{ color: "black" }}>
                          {" "}
                          {this.state.n2[3] >= 20 ? this.state.you2[3] : "-"}
                        </h5>
                      </Col>
                    </Row>
                    <br></br>
                    <Row className="show-grid" style={{ textAlign: "center" }}>
                      <Col xs={2}> </Col>

                      <Col xs={4}>
                        <h5>Industry</h5>
                      </Col>
                      <Col xs={4}>
                        {" "}
                        <h5 style={{ color: "black" }}>
                          {" "}
                          {this.state.they2[0]}
                        </h5>
                      </Col>
                      <Col xs={4}>
                        {" "}
                        <h5 style={{ color: "black" }}>
                          {" "}
                          {this.state.they2[1]}
                        </h5>
                      </Col>
                      <Col xs={4}>
                        {" "}
                        <h5 style={{ color: "black" }}>
                          {" "}
                          {this.state.they2[2]}
                        </h5>
                      </Col>
                      <Col xs={4}>
                        {" "}
                        <h5 style={{ color: "black" }}>
                          {" "}
                          {this.state.they2[3]}
                        </h5>
                      </Col>
                    </Row>
                    <br></br>
                    <Row className="show-grid" style={{ textAlign: "center" }}>
                      <Col xs={2}> </Col>

                      <Col xs={4}>
                        <h5>+/-</h5>
                      </Col>
                      <Col xs={4}>
                        {" "}
                        <p>
                          {this.state.n2[0] >= 20 ? (
                            <p>
                              <MakeArrow arrow={this.state.diff2[0]} />
                              <b> {this.state.diff2[0]}</b>
                            </p>
                          ) : (
                            "-"
                          )}
                        </p>
                      </Col>
                      <Col xs={4}>
                        <p>
                          {this.state.n2[1] >= 20 ? (
                            <p>
                              <MakeArrow arrow={this.state.diff2[1]} />
                              <b> {this.state.diff2[1]}</b>
                            </p>
                          ) : (
                            "-"
                          )}
                        </p>
                      </Col>
                      <Col xs={4}>
                        <p>
                          {this.state.n2[2] >= 20 ? (
                            <p>
                              <MakeArrow arrow={this.state.diff2[2]} />
                              <b> {this.state.diff2[2]}</b>
                            </p>
                          ) : (
                            "-"
                          )}
                        </p>
                      </Col>
                      <Col xs={4}>
                        <p>
                          {this.state.n2[3] >= 20 ? (
                            <p>
                              <MakeArrow arrow={this.state.diff2[3]} />
                              <b> {this.state.diff2[3]}</b>
                            </p>
                          ) : (
                            "-"
                          )}
                        </p>
                      </Col>
                    </Row>
                    <Divider
                      style={{ maxWidth: "50vh", marginLeft: "15vh" }}
                    ></Divider>
                    <Row className="show-grid" style={{ textAlign: "center" }}>
                      <Col xs={2}> </Col>

                      <Col xs={4}>
                        <h5>n</h5>
                      </Col>
                      <Col xs={4}>
                        {" "}
                        <p>
                          <b>({this.state.n2[0]})</b>
                        </p>
                      </Col>
                      <Col xs={4}>
                        <p>
                          <b>({this.state.n2[1]})</b>
                        </p>
                      </Col>
                      <Col xs={4}>
                        <p>
                          <b>({this.state.n2[2]})</b>
                        </p>
                      </Col>
                      <Col xs={4}>
                        <p>
                          <b>({this.state.n2[3]})</b>
                        </p>
                      </Col>
                    </Row>
                  </Grid>
                </Col>
              </Row>
            </Grid>
          </Panel>
          <Panel
            className="shadowed-panel"
            header={<h5 style={{ padding: "4px" }}>By Orientation</h5>}
            eventKey={3}
            id="panel3"
          >
            <Grid fluid>
              <Row className="show-grid" style={{ textAlign: "center" }}>
                <hr></hr>
                <Col xs={12}>
                  <h5>Internal Inclusion Score</h5>
                  <InclusionChart
                    provider={this.props.provider}
                    whereClause={this.props.whereClause}
                    chartID={"chartThree"}
                    query={"inclusion_orientation"}
                  />
                </Col>
                <Col xs="12">
                  <h5>External Comparison</h5>
                  <br></br>
                  <Grid fluid>
                    <Row className="show-grid" style={{ textAlign: "center" }}>
                      <Col xs={8}> </Col>

                      <Col xs={4}>
                        <h5>{this.state.cols4[0]}</h5>
                      </Col>
                      <Col xs={4}>
                        <h5>{this.state.cols4[1]}</h5>
                      </Col>
                      <Col xs={4}>
                        <ReactTooltip id="unkTop" place="top" effect="solid">
                          <b>
                            Undisclosed respondents either opted to not reply or
                            answered an unknown answer.
                          </b>
                        </ReactTooltip>
                        <h5 data-tip data-for="unkTop">
                          {this.state.cols4[2]}
                        </h5>
                      </Col>
                    </Row>
                    <Divider
                      style={{ maxWidth: "50vh", marginLeft: "15vh" }}
                    ></Divider>

                    <Row className="show-grid" style={{ textAlign: "center" }}>
                      <Col xs={4}> </Col>
                      <Col xs={4}>
                        {" "}
                        <h5>You</h5>
                      </Col>
                      <Col xs={4}>
                        {" "}
                        <h5 style={{ color: "black" }}>
                          {" "}
                          {this.state.n4[0] >= 20 ? this.state.you4[0] : "-"}
                        </h5>
                      </Col>
                      <Col xs={4}>
                        {" "}
                        <h5 style={{ color: "black" }}>
                          {" "}
                          {this.state.n4[1] >= 20 ? this.state.you4[1] : "-"}
                        </h5>
                      </Col>
                      <Col xs={4}>
                        {" "}
                        <h5 style={{ color: "black" }}>
                          {" "}
                          {this.state.n4[2] >= 20 ? this.state.you4[2] : "-"}
                        </h5>
                      </Col>
                    </Row>
                    <br></br>
                    <Row className="show-grid" style={{ textAlign: "center" }}>
                      <Col xs={4}> </Col>

                      <Col xs={4}>
                        <h5>Industry</h5>
                      </Col>
                      <Col xs={4}>
                        {" "}
                        <h5 style={{ color: "black" }}>
                          {" "}
                          {this.state.they4[0]}
                        </h5>
                      </Col>
                      <Col xs={4}>
                        {" "}
                        <h5 style={{ color: "black" }}>
                          {" "}
                          {this.state.they4[1]}
                        </h5>
                      </Col>
                      <Col xs={4}>
                        {" "}
                        <h5 style={{ color: "black" }}>
                          {" "}
                          {this.state.they4[2]}
                        </h5>
                      </Col>
                    </Row>
                    <br></br>
                    <Row className="show-grid" style={{ textAlign: "center" }}>
                      <Col xs={4}> </Col>

                      <Col xs={4}>
                        <h5>+/-</h5>
                      </Col>
                      <Col xs={4}>
                        {" "}
                        <p>
                          {this.state.n4[0] >= 20 ? (
                            <p>
                              <MakeArrow arrow={this.state.diff4[0]} />
                              <b> {this.state.diff4[0]}</b>
                            </p>
                          ) : (
                            "-"
                          )}
                        </p>
                      </Col>
                      <Col xs={4}>
                        <p>
                          {this.state.n4[1] >= 20 ? (
                            <p>
                              <MakeArrow arrow={this.state.diff4[1]} />
                              <b> {this.state.diff4[1]}</b>
                            </p>
                          ) : (
                            "-"
                          )}
                        </p>
                      </Col>
                      <Col xs={4}>
                        <p>
                          {this.state.n4[2] >= 20 ? (
                            <p>
                              <MakeArrow arrow={this.state.diff4[2]} />
                              <b> {this.state.diff4[2]}</b>
                            </p>
                          ) : (
                            "-"
                          )}
                        </p>
                      </Col>
                    </Row>
                    <Divider
                      style={{ maxWidth: "50vh", marginLeft: "15vh" }}
                    ></Divider>
                    <Row className="show-grid" style={{ textAlign: "center" }}>
                      <Col xs={4}> </Col>

                      <Col xs={4}>
                        <h5>n</h5>
                      </Col>
                      <Col xs={4}>
                        {" "}
                        <p>
                          <b>({this.state.n4[0]})</b>
                        </p>
                      </Col>
                      <Col xs={4}>
                        <p>
                          <b>({this.state.n4[1]})</b>
                        </p>
                      </Col>
                      <Col xs={4}>
                        <p>
                          <b>({this.state.n4[2]})</b>
                        </p>
                      </Col>
                    </Row>
                  </Grid>
                </Col>
              </Row>
            </Grid>
          </Panel>
          <Panel
            className="shadowed-panel"
            header={<h5 style={{ padding: "4px" }}>By Seniority</h5>}
            eventKey={3}
            id="panel3"
          >
            <Grid fluid>
              <Row className="show-grid" style={{ textAlign: "center" }}>
                <hr></hr>
                <Col xs={12}>
                  <h5>Internal Inclusion Score</h5>
                  <InclusionChart
                    provider={this.props.provider}
                    whereClause={this.props.whereClause}
                    chartID={"chartFour"}
                    query={"inclusion_leadership"}
                  />
                </Col>
                <Col xs="12">
                  <h5>External Comparison</h5>
                  <br></br>
                  <Grid fluid>
                    <Row className="show-grid" style={{ textAlign: "center" }}>
                      <Col xs={8}> </Col>

                      <Col xs={6}>
                        <h5>{this.state.cols5[0]}</h5>
                      </Col>
                      <Col xs={6}>
                        <h5>{this.state.cols5[1]}</h5>
                      </Col>
                    </Row>
                    <Divider
                      style={{ maxWidth: "50vh", marginLeft: "15vh" }}
                    ></Divider>

                    <Row className="show-grid" style={{ textAlign: "center" }}>
                      <Col xs={4}> </Col>
                      <Col xs={4}>
                        {" "}
                        <h5>You</h5>
                      </Col>
                      <Col xs={6}>
                        {" "}
                        <h5 style={{ color: "black" }}>
                          {" "}
                          {this.state.n5[0] >= 20 ? this.state.you5[0] : "-"}
                        </h5>
                      </Col>
                      <Col xs={6}>
                        {" "}
                        <h5 style={{ color: "black" }}>
                          {" "}
                          {this.state.n5[1] >= 20 ? this.state.you5[1] : "-"}
                        </h5>
                      </Col>
                    </Row>
                    <br></br>
                    <Row className="show-grid" style={{ textAlign: "center" }}>
                      <Col xs={4}> </Col>

                      <Col xs={4}>
                        <h5>Industry</h5>
                      </Col>
                      <Col xs={6}>
                        {" "}
                        <h5 style={{ color: "black" }}>
                          {" "}
                          {this.state.they5[0]}
                        </h5>
                      </Col>
                      <Col xs={6}>
                        {" "}
                        <h5 style={{ color: "black" }}>
                          {" "}
                          {this.state.they5[1]}
                        </h5>
                      </Col>
                    </Row>
                    <br></br>
                    <Row className="show-grid" style={{ textAlign: "center" }}>
                      <Col xs={4}> </Col>

                      <Col xs={4}>
                        <h5>+/-</h5>
                      </Col>
                      <Col xs={6}>
                        {" "}
                        <p>
                          {this.state.n5[0] >= 20 ? (
                            <p>
                              <MakeArrow arrow={this.state.diff5[0]} />
                              <b> {this.state.diff5[0]}</b>
                            </p>
                          ) : (
                            "-"
                          )}
                        </p>
                      </Col>
                      <Col xs={6}>
                        <p>
                          {this.state.n5[1] >= 20 ? (
                            <p>
                              <MakeArrow arrow={this.state.diff5[1]} />
                              <b> {this.state.diff5[1]}</b>
                            </p>
                          ) : (
                            "-"
                          )}
                        </p>
                      </Col>
                    </Row>
                    <Divider
                      style={{ maxWidth: "50vh", marginLeft: "15vh" }}
                    ></Divider>
                    <Row className="show-grid" style={{ textAlign: "center" }}>
                      <Col xs={4}> </Col>

                      <Col xs={4}>
                        <h5>n</h5>
                      </Col>
                      <Col xs={6}>
                        {" "}
                        <p>
                          <b>({this.state.n5[0]})</b>
                        </p>
                      </Col>
                      <Col xs={6}>
                        <p>
                          <b>({this.state.n5[1]})</b>
                        </p>
                      </Col>
                    </Row>
                  </Grid>
                </Col>
              </Row>
            </Grid>
          </Panel>
          <Panel
            className="shadowed-panel"
            header={<h5 style={{ padding: "4px" }}>By Disability</h5>}
            eventKey={3}
            id="panel3"
          >
            <Grid fluid>
              <Row className="show-grid" style={{ textAlign: "center" }}>
                <hr></hr>
                <Col xs={12}>
                  <h5>Internal Inclusion Score</h5>
                  <InclusionChart
                    provider={this.props.provider}
                    whereClause={this.props.whereClause}
                    chartID={"chartFive"}
                    query={"inclusion_disability"}
                  />
                </Col>
                <Col xs="12">
                  <h5>External Comparison</h5>
                  <br></br>
                  <Grid fluid>
                    <Row className="show-grid" style={{ textAlign: "center" }}>
                      <Col xs={8}> </Col>

                      <Col xs={4}>
                        <h5>{this.state.cols6[0]}</h5>
                      </Col>
                      <Col xs={4}>
                        <h5>{this.state.cols6[1]}</h5>
                      </Col>
                      <Col xs={4}>
                        <ReactTooltip id="unkTop" place="top" effect="solid">
                          <b>
                            Undisclosed respondents either opted to not reply or
                            answered an unknown answer.
                          </b>
                        </ReactTooltip>
                        <h5 data-tip data-for="unkTop">
                          {this.state.cols6[2]}
                        </h5>
                      </Col>
                    </Row>
                    <Divider
                      style={{ maxWidth: "50vh", marginLeft: "15vh" }}
                    ></Divider>

                    <Row className="show-grid" style={{ textAlign: "center" }}>
                      <Col xs={4}> </Col>
                      <Col xs={4}>
                        {" "}
                        <h5>You</h5>
                      </Col>
                      <Col xs={4}>
                        {" "}
                        <h5 style={{ color: "black" }}>
                          {" "}
                          {this.state.n6[0] >= 20 ? this.state.you6[0] : "-"}
                        </h5>
                      </Col>
                      <Col xs={4}>
                        {" "}
                        <h5 style={{ color: "black" }}>
                          {" "}
                          {this.state.n6[1] >= 20 ? this.state.you6[1] : "-"}
                        </h5>
                      </Col>
                    </Row>
                    <br></br>
                    <Row className="show-grid" style={{ textAlign: "center" }}>
                      <Col xs={4}> </Col>

                      <Col xs={4}>
                        <h5>Industry</h5>
                      </Col>
                      <Col xs={4}>
                        {" "}
                        <h5 style={{ color: "black" }}>
                          {" "}
                          {this.state.they6[0]}
                        </h5>
                      </Col>
                      <Col xs={4}>
                        {" "}
                        <h5 style={{ color: "black" }}>
                          {" "}
                          {this.state.they6[1]}
                        </h5>
                      </Col>
                    </Row>
                    <br></br>
                    <Row className="show-grid" style={{ textAlign: "center" }}>
                      <Col xs={4}> </Col>

                      <Col xs={4}>
                        <h5>+/-</h5>
                      </Col>
                      <Col xs={4}>
                        {" "}
                        <p>
                          {this.state.n6[0] >= 20 ? (
                            <p>
                              <MakeArrow arrow={this.state.diff6[0]} />
                              <b> {this.state.diff6[0]}</b>
                            </p>
                          ) : (
                            "-"
                          )}
                        </p>
                      </Col>
                      <Col xs={4}>
                        <p>
                          {this.state.n6[1] >= 20 ? (
                            <p>
                              <MakeArrow arrow={this.state.diff6[1]} />
                              <b> {this.state.diff6[1]}</b>
                            </p>
                          ) : (
                            "-"
                          )}
                        </p>
                      </Col>
                    </Row>
                    <Divider
                      style={{ maxWidth: "50vh", marginLeft: "15vh" }}
                    ></Divider>
                    <Row className="show-grid" style={{ textAlign: "center" }}>
                      <Col xs={4}> </Col>

                      <Col xs={4}>
                        <h5>n</h5>
                      </Col>
                      <Col xs={4}>
                        {" "}
                        <p>
                          <b>({this.state.n6[0]})</b>
                        </p>
                      </Col>
                      <Col xs={4}>
                        <p>
                          <b>({this.state.n6[1]})</b>
                        </p>
                      </Col>
                    </Row>
                  </Grid>
                </Col>
              </Row>
            </Grid>
          </Panel>
          <Panel
            className="shadowed-panel"
            header={<h5 style={{ padding: "4px" }}>By Age Group</h5>}
            eventKey={2}
            id="panel2"
          >
            {" "}
            <Grid fluid>
              <Row className="show-grid" style={{ textAlign: "center" }}>
                <hr></hr>
                <Col xs={8}>
                  <h5>Internal Inclusion Score</h5>
                  <InclusionChart
                    provider={this.props.provider}
                    whereClause={this.props.whereClause}
                    chartID={"chartSeven"}
                    query={"inclusion_age"}
                  />
                </Col>
                <Col xs="16">
                  <h5>External Comparison</h5>
                  <br></br>
                  <Grid fluid>
                    <Row className="show-grid" style={{ textAlign: "center" }}>
                      <Col xs={6}> </Col>

                      <Col xs={2}>
                        <h5>{this.state.cols3[0]}</h5>
                      </Col>
                      <Col xs={2}>
                        <h5>{this.state.cols3[1]}</h5>
                      </Col>
                      <Col xs={2}>
                        <h5>{this.state.cols3[2]}</h5>
                      </Col>
                      <Col xs={2}>
                        <h5>{this.state.cols3[3]}</h5>
                      </Col>
                      <Col xs={2}>
                        <h5>{this.state.cols3[4]}</h5>
                      </Col>
                      <Col xs={2}>
                        <h5>{this.state.cols3[5]}</h5>
                      </Col>
                    </Row>
                    <Divider
                      style={{ maxWidth: "65vh", marginLeft: "15vh" }}
                    ></Divider>

                    <Row className="show-grid" style={{ textAlign: "center" }}>
                      <Col xs={4}> </Col>
                      <Col xs={2}>
                        {" "}
                        <h5>You</h5>
                      </Col>
                      <Col xs={2}>
                        {" "}
                        <h5 style={{ color: "black" }}>
                          {" "}
                          {this.state.n3[0] >= 20 ? this.state.you3[0] : "-"}
                        </h5>
                      </Col>
                      <Col xs={2}>
                        {" "}
                        <h5 style={{ color: "black" }}>
                          {" "}
                          {this.state.n3[1] >= 20 ? this.state.you3[1] : "-"}
                        </h5>
                      </Col>
                      <Col xs={2}>
                        {" "}
                        <h5 style={{ color: "black" }}>
                          {" "}
                          {this.state.n3[2] >= 20 ? this.state.you3[2] : "-"}
                        </h5>
                      </Col>
                      <Col xs={2}>
                        {" "}
                        <h5 style={{ color: "black" }}>
                          {" "}
                          {this.state.n3[3] >= 20 ? this.state.you3[3] : "-"}
                        </h5>
                      </Col>
                      <Col xs={2}>
                        {" "}
                        <h5 style={{ color: "black" }}>
                          {" "}
                          {this.state.n3[4] >= 20 ? this.state.you3[4] : "-"}
                        </h5>
                      </Col>
                      <Col xs={2}>
                        {" "}
                        <h5 style={{ color: "black" }}>
                          {" "}
                          {this.state.n3[5] >= 20 ? this.state.you3[5] : "-"}
                        </h5>
                      </Col>
                    </Row>
                    <br></br>
                    <Row className="show-grid" style={{ textAlign: "center" }}>
                      <Col xs={4}> </Col>

                      <Col xs={2}>
                        <h5>Industry</h5>
                      </Col>
                      <Col xs={2}>
                        {" "}
                        <h5 style={{ color: "black" }}>
                          {" "}
                          {this.state.they3[0]}
                        </h5>
                      </Col>
                      <Col xs={2}>
                        {" "}
                        <h5 style={{ color: "black" }}>
                          {" "}
                          {this.state.they3[1]}
                        </h5>
                      </Col>
                      <Col xs={2}>
                        {" "}
                        <h5 style={{ color: "black" }}>
                          {" "}
                          {this.state.they3[2]}
                        </h5>
                      </Col>
                      <Col xs={2}>
                        {" "}
                        <h5 style={{ color: "black" }}>
                          {" "}
                          {this.state.they3[3]}
                        </h5>
                      </Col>
                      <Col xs={2}>
                        {" "}
                        <h5 style={{ color: "black" }}>
                          {" "}
                          {this.state.they3[4]}
                        </h5>
                      </Col>
                      <Col xs={2}>
                        {" "}
                        <h5 style={{ color: "black" }}>
                          {" "}
                          {this.state.they3[5]}
                        </h5>
                      </Col>
                    </Row>
                    <br></br>
                    <Row className="show-grid" style={{ textAlign: "center" }}>
                      <Col xs={4}> </Col>

                      <Col xs={2}>
                        <h5>+/-</h5>
                      </Col>
                      <Col xs={2}>
                        {" "}
                        <p>
                          {this.state.n3[0] >= 20 ? (
                            <p>
                              <MakeArrow arrow={this.state.diff3[0]} />
                              <b> {this.state.diff3[0]}</b>
                            </p>
                          ) : (
                            "-"
                          )}
                        </p>
                      </Col>
                      <Col xs={2}>
                        <p>
                          {this.state.n3[1] >= 20 ? (
                            <p>
                              <MakeArrow arrow={this.state.diff3[1]} />
                              <b> {this.state.diff3[1]}</b>
                            </p>
                          ) : (
                            "-"
                          )}
                        </p>
                      </Col>
                      <Col xs={2}>
                        <p>
                          {this.state.n3[2] >= 20 ? (
                            <p>
                              <MakeArrow arrow={this.state.diff3[2]} />
                              <b> {this.state.diff3[2]}</b>
                            </p>
                          ) : (
                            "-"
                          )}
                        </p>
                      </Col>
                      <Col xs={2}>
                        {" "}
                        <p>
                          {this.state.n3[3] >= 20 ? (
                            <p>
                              <MakeArrow arrow={this.state.diff3[3]} />
                              <b> {this.state.diff3[3]}</b>
                            </p>
                          ) : (
                            "-"
                          )}
                        </p>
                      </Col>
                      <Col xs={2}>
                        <p>
                          {this.state.n3[4] >= 20 ? (
                            <p>
                              <MakeArrow arrow={this.state.diff3[4]} />
                              <b> {this.state.diff3[4]}</b>
                            </p>
                          ) : (
                            "-"
                          )}
                        </p>
                      </Col>
                      <Col xs={2}>
                        <p>
                          {this.state.n3[5] >= 20 ? (
                            <p>
                              <MakeArrow arrow={this.state.diff3[5]} />
                              <b> {this.state.diff3[5]}</b>
                            </p>
                          ) : (
                            "-"
                          )}
                        </p>
                      </Col>
                    </Row>
                    <Divider
                      style={{ maxWidth: "50vh", marginLeft: "15vh" }}
                    ></Divider>
                    <Row className="show-grid" style={{ textAlign: "center" }}>
                      <Col xs={4}> </Col>
                      <Col xs={2}>
                        <h5>n</h5>
                      </Col>
                      <Col xs={2}>
                        {" "}
                        <p>
                          <b>({this.state.n3[0]})</b>
                        </p>
                      </Col>
                      <Col xs={2}>
                        <p>
                          <b>({this.state.n3[1]})</b>
                        </p>
                      </Col>
                      <Col xs={2}>
                        <p>
                          <b>({this.state.n3[2]})</b>
                        </p>
                      </Col>
                      <Col xs={2}>
                        {" "}
                        <p>
                          <b>({this.state.n3[3]})</b>
                        </p>
                      </Col>
                      <Col xs={2}>
                        <p>
                          <b>({this.state.n3[4]})</b>
                        </p>
                      </Col>
                      <Col xs={2}>
                        <p>
                          <b>({this.state.n3[5]})</b>
                        </p>
                      </Col>
                    </Row>
                  </Grid>
                </Col>
              </Row>
            </Grid>
          </Panel>
          <Panel
            className="shadowed-panel"
            header={<h5 style={{ padding: "4px" }}>By Remote vs. In-office</h5>}
            eventKey={2}
            id="panel2"
          >
            {" "}
            <Grid fluid>
              <Row className="show-grid" style={{ textAlign: "center" }}>
                <hr></hr>
                <Col xs={12}>
                  <h5>Internal Inclusion Score</h5>
                  <InclusionChart
                    provider={this.props.provider}
                    whereClause={this.props.whereClause}
                    chartID={"chartSeventy"}
                    query={"inclusion_remote"}
                  />
                </Col>
                <Col xs="12">
                  <h5>External Comparison</h5>
                  <br></br>
                  <Grid fluid>
                    <Row className="show-grid" style={{ textAlign: "center" }}>
                      <Col xs={6}> </Col>

                      <Col xs={4}>
                        <h5>{this.state.cols70[0]}</h5>
                      </Col>
                      <Col xs={4}>
                        <h5>{this.state.cols70[1]}</h5>
                      </Col>
                      <Col xs={4}>
                        <h5>{this.state.cols70[2]}</h5>
                      </Col>
                      <Col xs={4}>
                        <h5>{this.state.cols70[3]}</h5>
                      </Col>
                    </Row>
                    <Divider
                      style={{ maxWidth: "45vh", marginLeft: "15vh" }}
                    ></Divider>

                    <Row className="show-grid" style={{ textAlign: "center" }}>
                      <Col xs={2}> </Col>
                      <Col xs={4}>
                        {" "}
                        <h5>You</h5>
                      </Col>
                      <Col xs={4}>
                        {" "}
                        <h5 style={{ color: "black" }}>
                          {" "}
                          {this.state.n70[0] >= 20 ? this.state.you70[0] : "-"}
                        </h5>
                      </Col>
                      <Col xs={4}>
                        {" "}
                        <h5 style={{ color: "black" }}>
                          {" "}
                          {this.state.n70[1] >= 20 ? this.state.you70[1] : "-"}
                        </h5>
                      </Col>
                      <Col xs={4}>
                        {" "}
                        <h5 style={{ color: "black" }}>
                          {" "}
                          {this.state.n70[2] >= 20 ? this.state.you70[2] : "-"}
                        </h5>
                      </Col>
                      <Col xs={4}>
                        {" "}
                        <h5 style={{ color: "black" }}>
                          {" "}
                          {this.state.n70[3] >= 20 ? this.state.you70[3] : "-"}
                        </h5>
                      </Col>
                    </Row>
                    <br></br>
                    <Row className="show-grid" style={{ textAlign: "center" }}>
                      <Col xs={2}> </Col>

                      <Col xs={4}>
                        <h5>Industry</h5>
                      </Col>
                      <Col xs={4}>
                        {" "}
                        <h5 style={{ color: "black" }}>
                          {" "}
                          {this.state.they70[0]}
                        </h5>
                      </Col>
                      <Col xs={4}>
                        {" "}
                        <h5 style={{ color: "black" }}>
                          {" "}
                          {this.state.they70[1]}
                        </h5>
                      </Col>
                      <Col xs={4}>
                        {" "}
                        <h5 style={{ color: "black" }}>
                          {" "}
                          {this.state.they70[2]}
                        </h5>
                      </Col>
                      <Col xs={4}>
                        {" "}
                        <h5 style={{ color: "black" }}>
                          {" "}
                          {this.state.they70[3]}
                        </h5>
                      </Col>
                    </Row>
                    <br></br>
                    <Row className="show-grid" style={{ textAlign: "center" }}>
                      <Col xs={2}> </Col>

                      <Col xs={4}>
                        <h5>+/-</h5>
                      </Col>
                      <Col xs={4}>
                        {" "}
                        <p>
                          {this.state.n70[0] >= 20 ? (
                            <p>
                              <MakeArrow arrow={this.state.diff70[0]} />
                              <b> {this.state.diff70[0]}</b>
                            </p>
                          ) : (
                            "-"
                          )}
                        </p>
                      </Col>
                      <Col xs={4}>
                        <p>
                          {this.state.n70[1] >= 20 ? (
                            <p>
                              <MakeArrow arrow={this.state.diff70[1]} />
                              <b> {this.state.diff70[1]}</b>
                            </p>
                          ) : (
                            "-"
                          )}
                        </p>
                      </Col>
                      <Col xs={4}>
                        <p>
                          {this.state.n70[2] >= 20 ? (
                            <p>
                              <MakeArrow arrow={this.state.diff70[2]} />
                              <b> {this.state.diff70[2]}</b>
                            </p>
                          ) : (
                            "-"
                          )}
                        </p>
                      </Col>
                      <Col xs={4}>
                        <p>
                          {this.state.n70[3] >= 20 ? (
                            <p>
                              <MakeArrow arrow={this.state.diff70[3]} />
                              <b> {this.state.diff70[3]}</b>
                            </p>
                          ) : (
                            "-"
                          )}
                        </p>
                      </Col>
                    </Row>
                    <Divider
                      style={{ maxWidth: "50vh", marginLeft: "15vh" }}
                    ></Divider>
                    <Row className="show-grid" style={{ textAlign: "center" }}>
                      <Col xs={2}> </Col>
                      <Col xs={4}>
                        <h5>n</h5>
                      </Col>
                      <Col xs={4}>
                        {" "}
                        <p>
                          <b>({this.state.n70[0]})</b>
                        </p>
                      </Col>
                      <Col xs={4}>
                        <p>
                          <b>({this.state.n70[1]})</b>
                        </p>
                      </Col>
                      <Col xs={4}>
                        <p>
                          <b>({this.state.n70[2]})</b>
                        </p>
                      </Col>
                      <Col xs={4}>
                        <p>
                          <b>({this.state.n70[3]})</b>
                        </p>
                      </Col>
                    </Row>
                  </Grid>
                </Col>
              </Row>
            </Grid>
          </Panel>
        </PanelGroup>
      </div>
    );
  }
}

export default Inclusion;
