// Core libraries: React, React Bootstrap
import React, { Component } from "react";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";

import { trackPromise } from "react-promise-tracker";

class Ratio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      treeData: [],
      treeData2: [],
    };
  }
  componentDidMount() {
    trackPromise(
      Promise.all([
        fetch(
          `https://ids-express-server.azurewebsites.net/ratio_industry?provider=` +
            this.props.provider
        )
          .then((response) => response.json())
          .then((data) => {
            this.setState({
              treeData: data.rowData.recordset,
              treeData2: data.rowData2.recordset,
            });
          }),
      ])
    );

    var root = am5.Root.new("ratio");

    this.root = root;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.treeData !== this.state.treeData ||
      prevProps.layer !== this.props.layer
    ) {
      if (this.props.layer.value === "Layer_2") {
        var data = this.state.treeData2;
      } else {
        var data = this.state.treeData;
      }
      this.root.dispose();
      var root = am5.Root.new("ratio");
      this.root = root;

      this.root.setThemes([am5themes_Animated.new(this.root)]);

      var chart = this.root.container.children.push(
        am5xy.XYChart.new(this.root, {
          panX: false,
          panY: false,
          wheelX: "panX",
          wheelY: "zoomX",
          layout: this.root.verticalLayout,
        })
      );

      var legend = chart.children.push(
        am5.Legend.new(this.root, {
          centerX: am5.p50,
          x: am5.p50,
        })
      );

      var yAxis = chart.yAxes.push(
        am5xy.CategoryAxis.new(this.root, {
          categoryField: "Layer",
          renderer: am5xy.AxisRendererY.new(this.root, {
            inversed: true,
            cellStartLocation: 0.1,
            cellEndLocation: 0.9,
          }),
        })
      );

      yAxis.data.setAll(data);

      var xAxis = chart.xAxes.push(
        am5xy.ValueAxis.new(this.root, {
          renderer: am5xy.AxisRendererX.new(this.root, {}),
          min: 0,
        })
      );

      yAxis.get("renderer").labels.template.setAll({
        oversizedBehavior: "fit",
        maxHeight: 20,
      });

      function createSeries(rt, field, name, dt) {
        var series = chart.series.push(
          am5xy.ColumnSeries.new(rt, {
            name: name,
            xAxis: xAxis,
            yAxis: yAxis,
            valueXField: field,
            categoryYField: "Layer",
            sequencedInterpolation: true,
            tooltip: am5.Tooltip.new(rt, {
              pointerOrientation: "horizontal",
              labelText: "[bold]{name}[/]\n{categoryY}: {valueX}",
            }),
          })
        );

        series.columns.template.setAll({
          height: am5.p100,
        });

        series.bullets.push(function () {
          return am5.Bullet.new(rt, {
            locationX: 1,
            locationY: 0.5,
            sprite: am5.Label.new(rt, {
              centerY: am5.p50,
              text: "{valueX}",
              populateText: true,
            }),
          });
        });

        series.columns.template.setAll({
          tooltipText: "{categoryY} - {name}: {valueX}",
        });

        series.bullets.push(function () {
          return am5.Bullet.new(rt, {
            locationX: 1,
            locationY: 0.5,
            sprite: am5.Label.new(rt, {
              centerX: am5.p100,
              centerY: am5.p50,
              text: "{name}",
              fill: am5.color(0xffffff),
              populateText: true,
            }),
          });
        });

        series.data.setAll(dt);
        series.appear();

        return series;
      }

      createSeries(this.root, "Your_Ratio", "You", data);
      createSeries(this.root, "Industry_Ratio", "Industry", data);

      // Add legend
      // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
      var legend = chart.children.push(
        am5.Legend.new(this.root, {
          centerX: am5.p50,
          x: am5.p50,
        })
      );

      legend.data.setAll(chart.series.values);

      // Add cursor
      // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
      var cursor = chart.set(
        "cursor",
        am5xy.XYCursor.new(this.root, {
          behavior: "zoomY",
        })
      );
      cursor.lineY.set("forceHidden", false);
      cursor.lineX.set("forceHidden", false);

      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      chart.appear(1000, 100);

      let exporting = am5plugins_exporting.Exporting.new(this.root, {
        menu: am5plugins_exporting.ExportingMenu.new(this.root, {}),
        dataSource: data,
      });
    }
  }

  componentWillUnmount() {
    if (this.root) {
      this.root.dispose();
    }
  }

  render() {
    return (
      <div>
        <div id="ratio" style={{ width: "100%", height: "950px" }}></div>
        <br></br>
      </div>
    );
  }
}

export default Ratio;
