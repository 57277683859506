// Core libraries: React, React Bootstrap
import React, { Component } from "react";

import { Divider } from "rsuite";

import MakeComparison from "../InterfaceComponents/MakeComparison";

import dei2 from "../Images/dei_siz.jpg";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

class Scorecard extends Component {
  constructor(props) {
    super(props);
    this.makechart = this.makechart.bind(this);
  }

  makechart() {
    this.root.setThemes([am5themes_Animated.new(this.root)]);

    var chart = this.root.container.children.push(
      am5xy.XYChart.new(this.root, {
        panX: false,
        panY: false,
        wheelX: "none",
        wheelY: "none",
        layout: this.root.verticalLayout,
      })
    );

    var data = [
      {
        category: "",
        open: 8,
        close: 14,
        average: this.props.diversityYou,
        industry: this.props.diversityThem,
      },
    ];

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    var yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(this.root, {
        categoryField: "category",
        renderer: am5xy.AxisRendererY.new(this.root, {
          cellStartLocation: 0.1,
          cellEndLocation: 0.9,
        }),
        tooltip: am5.Tooltip.new(this.root, {}),
      })
    );

    yAxis.data.setAll(data);

    var xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(this.root, {
        renderer: am5xy.AxisRendererX.new(this.root, {
          minGridDistance: 40,
        }),
      })
    );

    xAxis.get("renderer").grid.template.set("visible", false);

    var series = chart.series.push(
      am5xy.ColumnSeries.new(this.root, {
        xAxis: xAxis,
        yAxis: yAxis,
        openValueXField: "open",
        valueXField: "close",
        categoryYField: "category",
        fill: am5.color(0x6794dc),
      })
    );

    series.columns.template.setAll({
      height: 5,
    });

    series.data.setAll(data);

    // Add bullets
    series.bullets.push(function (rt) {
      return am5.Bullet.new(rt, {
        locationX: 0,
        sprite: am5.Circle.new(rt, {
          fill: am5.color(0x6794dc),
          radius: 10,
        }),
      });
    });

    series.bullets.push(function (rt) {
      return am5.Bullet.new(rt, {
        locationX: 1,
        sprite: am5.Circle.new(rt, {
          fill: am5.color(0x6794dc),
          radius: 10,
        }),
      });
    });

    var series2 = chart.series.push(
      am5xy.LineSeries.new(this.root, {
        name: "Your Score",
        xAxis: xAxis,
        yAxis: yAxis,
        valueXField: "average",
        categoryYField: "category",
      })
    );

    series2.strokes.template.setAll({
      visible: false,
    });

    series2.data.setAll(data);

    // Add bullets
    series2.bullets.push(function (rt) {
      return am5.Bullet.new(rt, {
        sprite: am5.Triangle.new(rt, {
          fill: am5.color(0x67b7dc),
          rotation: 0,
          width: 20,
          height: 20,
        }),
      });
    });

    var series4 = chart.series.push(
      am5xy.LineSeries.new(this.root, {
        name: "Industry Average",
        xAxis: xAxis,
        yAxis: yAxis,
        valueXField: "industry",
        categoryYField: "category",
      })
    );

    series4.strokes.template.setAll({
      visible: false,
    });

    series4.data.setAll(data);

    // Add bullets
    series4.bullets.push(function (rt) {
      return am5.Bullet.new(rt, {
        sprite: am5.Triangle.new(rt, {
          fill: am5.color(0x6794dc),
          rotation: 180,
          width: 3,
          height: 30,
        }),
      });
    });

    var series3 = chart.series.push(
      am5xy.LineSeries.new(this.root, {
        name: "Industry Range",
        xAxis: xAxis,
        yAxis: yAxis,
      })
    );

    series3.strokes.template.setAll({
      visible: false,
    });

    series3.data.setAll(data);

    // Add bullets
    series3.bullets.push(function (rt) {
      return am5.Bullet.new(rt, {
        locationX: 0,
        sprite: am5.Circle.new(rt, {
          fill: am5.color(0x6794dc),
          radius: 10,
        }),
      });
    });

    // Add legend
    var legend = chart.children.push(
      am5.Legend.new(this.root, {
        layout: this.root.verticalLayout,
        clickTarget: "none",
        x: am5.percent(60),
        centerX: am5.percent(50),
        layout: this.root.verticalLayout,
        paddingTop: 8,
      })
    );

    legend.labels.template.setAll({
      fontSize: 13,
    });

    legend.data.setAll([series2, series4, series3]);

    series.appear();
    chart.appear(1000, 100);
  }

  componentDidMount() {
    var root = am5.Root.new("chartdiv");
    this.root = root;
    this.makechart();
  }

  componentDidUpdate(prevProps, prevState) {
    this.root.dispose();
    var root = am5.Root.new("chartdiv");
    this.root = root;
    this.makechart();
  }

  componentWillUnmount() {
    if (this.root) {
      this.root.dispose();
    }
  }

  render() {
    return (
      <div>
        <div class="flipcard">
          <div class="flipcard-wrap">
            <div class="card card-front">
              <img src={dei2} alt="" class="card-img-top"></img>
              <div class="card-body">
                <h3>Diversity Component</h3>
                <Divider></Divider>
                <p>
                  <MakeComparison
                    you={this.props.diversityYou}
                    them={this.props.diversityThem}
                    ceil={15}
                    descrip={"Diversity"}
                  />
                </p>
                <br></br>
              </div>
            </div>
            <div class="card card-back">
              <div class="card-body">
                <div>
                  <br></br>
                  <br></br>
                  <br></br>

                  <h2>{this.props.diversityYou}</h2>

                  <div
                    id="chartdiv"
                    style={{ width: "100%", height: "400px" }}
                  ></div>
                  <br></br>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Scorecard;
