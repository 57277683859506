import React, { Component } from "react";
import { SocialIcon } from "react-social-icons";
import { Container } from "rsuite";

import Bain from "../Images/logo_white.svg";
import TM from "../Images/tmforum_logo.png";

class Footer extends Component {
  render() {
    return (
      <div style={{ bottom: "0", paddingTop: "5vh" }}>
        <div>
          <Container>
            <div class="footer-dark">
              <footer>
                <div class="container">
                  <div class="row">
                    <div
                      class="col-sm-6 col-md-3 item"
                      style={{ marginLeft: "4%" }}
                    >
                      <h3>Inclusion & Diversity</h3>
                      <img
                        src={Bain}
                        style={{ marginTop: "1vh", height: "10%" }}
                      ></img>
                      <br></br>
                      <img
                        src={TM}
                        style={{ marginTop: "1vh", height: "10%" }}
                      ></img>
                      <br></br>
                      <br></br>
                      <SocialIcon
                        url="https://www.linkedin.com/company/tm-forum"
                        style={{
                          height: 25,
                          width: 25,
                          marginRight: "5px",
                        }}
                      />
                      <SocialIcon
                        url="https://www.facebook.com/tmforum"
                        style={{
                          height: 25,
                          width: 25,
                          marginRight: "5px",
                        }}
                      />
                      <SocialIcon
                        network="youtube"
                        url="https://www.youtube.com/channel/UCLKFQ99UR0KRtF3BTQzurOw"
                        style={{
                          height: 25,
                          width: 25,
                          marginRight: "5px",
                        }}
                      />
                      <SocialIcon
                        url="https://twitter.com/tmforumorg"
                        style={{
                          height: 25,
                          width: 25,
                          marginRight: "5px",
                        }}
                      />
                    </div>
                    <div class="col-md-1 item text"></div>
                    <div class="col-sm-6 col-md-3 item">
                      <h3>Contact Us</h3>
                      <ul>
                        <li>
                          <a href="mailto:info@tmforum.org">
                            {" "}
                            General Inquiries
                          </a>
                        </li>
                        <li>
                          <a href="mailto:memberservices@tmforum.org">
                            Member Services
                          </a>
                        </li>
                        <li>
                          <a href="mailto:newmembers@tmforum.org">
                            Interested in Joining
                          </a>
                        </li>
                        <li>
                          <a href="mailto:tmforum@ccgrouppr.com">
                            Press Inquiries
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class="col-md-1 item text"></div>

                    <div class="col-sm-6 col-md-3 item">
                      <h3>Explore TM Forum</h3>
                      <ul>
                        <li>
                          <a href="https://www.tmforum.org/about-tm-forum/">
                            About TM Forum
                          </a>
                        </li>
                        <li>
                          <a href="https://www.tmforum.org/diversity-and-inclusion/">
                            About the Survey
                          </a>
                        </li>
                        <li>
                          <a href="https://www.tmforum.org/about-tm-forum/legal-branding/website-privacy-policy/">
                            Privacy & Policy
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <p class="copyright"> ©2023 Bain & Company</p>
                  <br></br>
                  <p style={{ color: "grey", fontSize: "11px" }}>
                    Graphs by{" "}
                    <a href="https://www.amcharts.com/terms-conditions/">
                      amCharts v5
                    </a>
                  </p>
                </div>
              </footer>
            </div>
          </Container>
        </div>
      </div>
    );
  }
}

export default Footer;
