// Core libraries: React, React Bootstrap
import React, { Component } from "react";

import { Icon } from "@rsuite/icons";
import { FaAngleUp, FaAngleDown, FaEquals } from "react-icons/fa";

class MakeArrow extends Component {
  render() {
    var arrow = this.props.arrow;
    if (arrow > 0) {
      return <Icon as={FaAngleUp} size="1em" style={{ color: "green" }} />;
    } else if (arrow === 0) {
      return <Icon as={FaEquals} size="0.5em" style={{ color: "black" }} />;
    } else {
      return (
        <Icon as={FaAngleDown} size="1em" style={{ color: "firebrick" }} />
      );
    }
  }
}

export default MakeArrow;
