// Core libraries: React, React Bootstrap
import React, { Component } from "react";
import { Route, Routes, Link } from "react-router-dom";

// Core UI Library: RSuite
import "rsuite/dist/rsuite.min.css";
import { Container, Header, Navbar, Nav, Drawer, Divider } from "rsuite";
import IconButton from "rsuite/IconButton";
import PlayIcon from "@rsuite/icons/legacy/Play";

import "bootstrap/dist/css/bootstrap.min.css";
import ReactTooltip from "react-tooltip";

// Images
import Bain from "../Images/logo_white.svg";

// Components
import Footer from "../InterfaceComponents/Footer";
import IDSReport from "../InterfaceComponents/IDSReport";
import Diversity from "../InterfaceComponents/Diversity";
import Inclusion from "../Charts/Inclusion";
import Verbatim from "../Charts/Verbatim";
import Logout from "../InterfaceComponents/Logout";

import { trackPromise } from "react-promise-tracker";

import { Icon } from "@rsuite/icons";
import {
  FaUsers,
  FaCommentDots,
  FaFilter,
  FaRestroom,
  FaBalanceScaleLeft,
  FaUser,
  FaBuilding,
} from "react-icons/fa";

import Select from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

const typeOptions = ["Network operators", "Service provider"].map((item) => ({
  label: item,
  value: item,
}));

const leadershipOptions = ["Leadership", "Non-leadership"].map((item) => ({
  label: item,
  value: item,
}));

const geoOptions = [
  { value: "EMEA", label: "Europe, Middle East, Asia" },
  { value: "AMER", label: "Americas" },
];

const sizeOptions = [
  { value: "Small", label: "Small (1-2000)" },
  { value: "Medium", label: "Medium (2000-5000)" },
  { value: "Large", label: "Large (5000+)" },
];

class IDSNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPanel: false,
      loading: true,
      loggedOut: false,
      chosenLeadership: [],
      chosenSize: [],
      chosenGeography: [],
      chosenType: [],
      chosenProvider: "",
      whereClause: "WHERE 1 = 1",
      numProviders: 0,
    };
    this._refreshData = this._refreshData.bind(this);
    this._onLeaderChange = this._onLeaderChange.bind(this);
    this._onSizeChange = this._onSizeChange.bind(this);
    this._onGeographyChange = this._onGeographyChange.bind(this);
    this._onTypeChange = this._onTypeChange.bind(this);
    this._showPanel = this._showPanel.bind(this);
    this._hidePanel = this._hidePanel.bind(this);
    this._refresh = this._refresh.bind(this);
    this._logOut = this._logOut.bind(this);
  }

  _refresh() {
    function getFields(input, field) {
      var output = [];
      for (var i = 0; i < input.length; ++i) output.push(input[i][field]);
      return output;
    }
    trackPromise(
      Promise.all([
        fetch(
          `https://ids-express-server.azurewebsites.net/countCompanies?whereClause=` +
            this.state.whereClause
        )
          .then((response) => response.json())
          .then((data) => {
            this.setState({
              numProviders: getFields(data.rowData.recordset, "Count")[0],
            });
          }),
      ])
    );
  }
  _logOut() {
    this.setState({ loggedOut: true });
  }

  _showPanel() {
    this.setState({ showPanel: true });
  }
  _hidePanel() {
    this.setState({ showPanel: false });
  }

  _refreshData() {
    this.setState({ loading: true });
    let leadershipArray = [];
    if (this.state.chosenLeadership && this.state.chosenLeadership.length) {
      this.state.chosenLeadership.map((o) => leadershipArray.push(o.value));
    }
    let sizeArray = [];
    if (this.state.chosenSize && this.state.chosenSize.length) {
      this.state.chosenSize.map((o) => sizeArray.push(o.value));
    }
    let geographyArray = [];
    if (this.state.chosenGeography && this.state.chosenGeography.length) {
      this.state.chosenGeography.map((o) => geographyArray.push(o.value + " "));
    }
    let typeArray = [];
    if (this.state.chosenType && this.state.chosenType.length) {
      this.state.chosenType.map((o) => typeArray.push(o.value));
    }

    trackPromise(
      Promise.all([
        fetch(
          `https://ids-express-server.azurewebsites.net/formatWhereClause?chosenLeadership=` +
            leadershipArray +
            `&chosenSize=` +
            sizeArray +
            `&chosenGeography=` +
            geographyArray +
            `&chosenType=` +
            typeArray,
          {
            mode: "no-cors",
          }
        )
          .then((response) => response.text())
          .then((response) => {
            this.setState({
              whereClause: response,
            });
          })
          .catch((err) => console.log(err)),
      ])
    );
    this._refresh();
    this.setState({ loading: false });
  }

  _onLeaderChange = (chosenLeadership) => {
    this.setState({ chosenLeadership });
  };

  _onSizeChange = (chosenSize) => {
    this.setState({ chosenSize });
  };

  _onGeographyChange = (chosenGeography) => {
    this.setState({ chosenGeography });
  };

  _onTypeChange = (chosenType) => {
    this.setState({ chosenType });
  };

  componentDidMount() {
    this._refresh();
    this.setState({ loading: false });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.whereClause !== this.state.whereClause) {
      this._refresh();
    }
  }

  render() {
    return (
      <div>
        <div>
          {this.state.loggedOut ? (
            <Logout />
          ) : (
            <div style={{ width: "100%", height: "0vh" }}></div>
          )}
        </div>
        <div
          style={{
            width: "100%",
            maxHeight: this.state.loggedOut ? "0vh" : "100vh",
            visibility: this.state.loggedOut ? "hidden" : "visible",
          }}
        >
          <div style={{ minHeight: "90vh", position: "relative" }}>
            <Container>
              <div>
                <Header>
                  <ReactTooltip id="idsNav" place="bottom" effect="solid">
                    How does your IDS score compare to the industry?
                  </ReactTooltip>
                  <ReactTooltip id="diversityNav" place="bottom" effect="solid">
                    Take a deeper look into your organization's diversity.
                  </ReactTooltip>
                  <ReactTooltip id="inclusionNav" place="bottom" effect="solid">
                    Take a deeper look into your organization's inclusion.
                  </ReactTooltip>
                  <ReactTooltip id="verbatimNav" place="bottom" effect="solid">
                    Get a pulse on how your employees feel about inclusion and
                    diversity.
                  </ReactTooltip>
                  <ReactTooltip id="filtersNav" place="bottom" effect="solid">
                    Change the comparison group.
                  </ReactTooltip>
                  <ReactTooltip id="logoutNav" place="bottom" effect="solid">
                    Log out of IDS Portal
                  </ReactTooltip>
                  <Navbar appearance="inverse">
                    <Navbar.Header>
                      <a
                        href="https://www.bain.com/"
                        className="navbar-brand logo"
                      >
                        <img
                          src={Bain}
                          height={16}
                          style={{ display: "flex", marginTop: "2px" }}
                        ></img>
                      </a>
                    </Navbar.Header>
                    <Navbar.Body>
                      <Nav>
                        <Nav.Item
                          data-tip
                          data-for="idsNav"
                          as={Link}
                          to="/ids"
                          icon={
                            <Icon
                              as={FaBalanceScaleLeft}
                              size="1em"
                              style={{ color: "#61dafb" }}
                            />
                          }
                        >
                          IDS Score
                        </Nav.Item>
                        <Nav.Item
                          data-tip
                          data-for="diversityNav"
                          as={Link}
                          to="/diversity"
                          icon={
                            <Icon
                              as={FaUsers}
                              size="1em"
                              style={{ color: "#61dafb" }}
                            />
                          }
                        >
                          Diversity
                        </Nav.Item>
                        <Nav.Item
                          data-tip
                          data-for="inclusionNav"
                          as={Link}
                          to="/inclusion"
                          icon={
                            <Icon
                              as={FaRestroom}
                              size="1em"
                              style={{ color: "#61dafb" }}
                            />
                          }
                        >
                          Inclusion
                        </Nav.Item>
                        <Nav.Item
                          data-tip
                          data-for="verbatimNav"
                          as={Link}
                          to="/verbatim"
                          icon={
                            <Icon
                              as={FaCommentDots}
                              size="1em"
                              style={{ color: "#61dafb" }}
                            />
                          }
                        >
                          Drivers
                        </Nav.Item>
                      
                      </Nav>
                      <Nav pullRight>
                        <Nav.Item
                          data-tip
                          data-for="logoutNav"
                          style={{ fontColor: "firebrick" }}
                          as={Link}
                          to="/logout"
                          onSelect={() => this._logOut()}
                          icon={
                            <Icon
                              as={FaUser}
                              size="1em"
                              style={{ color: "#61dafb" }}
                            />
                          }
                        >
                          {this.props.provider}
                        </Nav.Item>
                      </Nav>
                    </Navbar.Body>
                  </Navbar>
                </Header>
              </div>
            </Container>
            <Routes>
              <Route
                path="/ids"
                element={
                  <IDSReport
                    provider={this.props.provider}
                    whereClause={this.state.whereClause}
                    loading={this.state.loading}
                  />
                }
              ></Route>
              <Route
                path="/diversity"
                element={
                  <Diversity
                    provider={this.props.provider}
                    whereClause={this.state.whereClause}
                  />
                }
              ></Route>
              <Route
                path="/inclusion"
                element={
                  <Inclusion
                    provider={this.props.provider}
                    whereClause={this.state.whereClause}
                  />
                }
              ></Route>
              <Route
                path="/verbatim"
                element={
                  <Verbatim
                    provider={this.props.provider}
                    whereClause={this.state.whereClause}
                  />
                }
              ></Route>
              <Route
                path="/"
                element={
                  <IDSReport
                    provider={this.props.provider}
                    whereClause={this.state.whereClause}
                    loading={this.state.loading}
                  />
                }
              ></Route>
              <Route
                path="/?"
                element={
                  <IDSReport
                    provider={this.props.provider}
                    whereClause={this.state.whereClause}
                    loading={this.state.loading}
                  />
                }
              ></Route>
              <Route path="/logout" element={<Logout />}></Route>
            </Routes>
          </div>
          <div style={{ maxHeight: "0vh" }}>
            <Drawer
              backdrop={true}
              open={this.state.showPanel}
              onClose={() => this._hidePanel()}
            >
              <Drawer.Header>
                <Drawer.Title>
                  <i>
                    Filters impact all tabs except for the <b>Drivers</b> tab,
                    for anonymity.
                  </i>
                </Drawer.Title>
                <Divider></Divider>
                <Drawer.Actions></Drawer.Actions>
              </Drawer.Header>
              <Drawer.Body>
                <div>
                  {" "}
                  <IconButton
                    icon={<PlayIcon />}
                    onClick={() => this._refreshData()}
                    color="green"
                    appearance="primary"
                    placement="right"
                  >
                    Apply Filters
                  </IconButton>
                  <br></br>
                  <br></br>
                  <p>
                    <Icon
                      as={FaBuilding}
                      size="1em"
                      style={{ color: "grey", marginRight: "6px" }}
                    />
                    Showing data for <b>{this.state.numProviders} </b>
                    companies based on filter criteria.
                  </p>
                  <br></br>
                  <Divider>Filter your comparison group</Divider>
                  <br></br>
                  <h5>Company Type</h5>
                  <br></br>
                  <Select
                    label=""
                    size="lg"
                    placement="bottomStart"
                    style={{ width: 300 }}
                    onChange={this._onTypeChange}
                    isClearable={true}
                    isSearchable={false}
                    closeMenuOnSelect={false}
                    isMulti
                    components={animatedComponents}
                    value={this.state.chosenType}
                    options={typeOptions}
                  />
                  <br></br>
                  <br></br>
                  <h5>Company Size</h5>
                  <br></br>
                  <Select
                    label=""
                    size="lg"
                    placement="bottomStart"
                    style={{ width: 300 }}
                    onChange={this._onSizeChange}
                    isClearable={true}
                    isSearchable={false}
                    closeMenuOnSelect={false}
                    isMulti
                    components={animatedComponents}
                    value={this.state.chosenSize}
                    options={sizeOptions}
                  />
                  <br></br>
                  <br></br>
                  <h5>Region</h5>
                  <br></br>
                  <Select
                    label=""
                    size="lg"
                    placement="bottomStart"
                    style={{ width: 300 }}
                    onChange={this._onGeographyChange}
                    isClearable={true}
                    isSearchable={false}
                    closeMenuOnSelect={false}
                    isMulti
                    components={animatedComponents}
                    value={this.state.chosenGeography}
                    options={geoOptions}
                  />
                  <br></br>
                  <Divider>Filter within your organization</Divider>
                  <br></br>
                  <h5>Seniority Level</h5>
                  <br></br>
                  <Select
                    label=""
                    size="lg"
                    placement="bottomStart"
                    style={{ width: 300 }}
                    onChange={this._onLeaderChange}
                    isClearable={true}
                    isSearchable={false}
                    closeMenuOnSelect={false}
                    isMulti
                    components={animatedComponents}
                    value={this.state.chosenLeadership}
                    options={leadershipOptions}
                  />
                </div>
              </Drawer.Body>
            </Drawer>
          </div>
          <Footer style={{ postition: "absolute", bottom: 0 }} />
        </div>
      </div>
    );
  }
}

export default IDSNew;
