// Core libraries: React, React Bootstrap
import React, { Component } from "react";

// Loading Components

// Tooltips & Icons
import { Divider } from "rsuite";

import MakeComparison from "../InterfaceComponents/MakeComparison";

import dei from "../Images/one_c.jpg";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5radar from "@amcharts/amcharts5/radar";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

class Gauge extends Component {
  constructor(props) {
    super(props);
    this.makechart = this.makechart.bind(this);
  }

  makechart() {
    this.root.setThemes([am5themes_Animated.new(this.root)]);

    var chart = this.root.container.children.push(
      am5radar.RadarChart.new(this.root, {
        panX: false,
        panY: false,
        startAngle: 180,
        endAngle: 360,
        radius: am5.percent(90),
        layout: this.root.verticalLayout,
      })
    );

    // Colors
    var colors = am5.ColorSet.new(this.root, {
      step: 2,
    });

    var color1 = colors.next();

    var axisRenderer1 = am5radar.AxisRendererCircular.new(this.root, {
      radius: -10,
      strokeOpacity: 1,
      strokeWidth: 6,
      inside: true,
      visible: false,
    });

    axisRenderer1.grid.template.setAll({
      forceHidden: true,
    });

    axisRenderer1.ticks.template.setAll({
      stroke: color1,
      visible: false,
      length: 10,
      strokeOpacity: 1,
      inside: true,
    });

    axisRenderer1.labels.template.setAll({
      radius: 15,
      inside: true,
      visible: false,
    });

    var xAxis1 = chart.xAxes.push(
      am5xy.ValueAxis.new(this.root, {
        maxDeviation: 0,
        min: 0,
        max: 30,
        strictMinMax: true,
        renderer: axisRenderer1,
      })
    );

    // Label
    var label1 = chart.seriesContainer.children.push(
      am5.Label.new(this.root, {
        fill: am5.color(0xffffff),
        x: -100,
        y: -60,
        width: 70,
        centerX: am5.percent(50),
        textAlign: "center",
        centerY: am5.percent(50),
        fontSize: "1em",
        text: "0",
        background: am5.RoundedRectangle.new(this.root, {
          fill: color1,
        }),
      })
    );

    // Add clock hand
    var axisDataItem1 = xAxis1.makeDataItem({
      value: 0,
      fill: color1,
      name: "Your Score",
    });

    var clockHand1 = am5radar.ClockHand.new(this.root, {
      pinRadius: 14,
      radius: am5.percent(98),
      bottomWidth: 10,
    });

    clockHand1.pin.setAll({
      fill: color1,
    });

    clockHand1.hand.setAll({
      fill: color1,
    });

    var bullet1 = axisDataItem1.set(
      "bullet",
      am5xy.AxisBullet.new(this.root, {
        sprite: clockHand1,
      })
    );

    xAxis1.createAxisRange(axisDataItem1);

    axisDataItem1.get("grid").set("forceHidden", true);
    axisDataItem1.get("tick").set("forceHidden", true);

    // Measurement #2

    // Axis
    var color2 = colors.next();

    var axisRenderer2 = am5radar.AxisRendererCircular.new(this.root, {
      //innerRadius: -40,
      strokeOpacity: 1,
      strokeWidth: 6,
    });

    axisRenderer2.grid.template.setAll({
      forceHidden: true,
    });

    axisRenderer2.ticks.template.setAll({
      visible: true,
      length: 10,
      strokeOpacity: 1,
    });

    axisRenderer2.labels.template.setAll({
      radius: 15,
    });

    var xAxis2 = chart.xAxes.push(
      am5xy.ValueAxis.new(this.root, {
        maxDeviation: 0,
        min: 0,
        max: 30,
        strictMinMax: true,
        renderer: axisRenderer2,
      })
    );

    // Label
    var label2 = chart.seriesContainer.children.push(
      am5.Label.new(this.root, {
        fill: am5.color(0xffffff),
        x: 100,
        y: -60,
        width: 70,
        centerX: am5.percent(50),
        textAlign: "center",
        centerY: am5.percent(50),
        fontSize: "1em",
        text: "0",
        background: am5.RoundedRectangle.new(this.root, {
          fill: color2,
        }),
      })
    );

    // Add clock hand
    var axisDataItem2 = xAxis2.makeDataItem({
      value: 0,
      fill: color2,
      name: "Industry Average",
    });

    var clockHand2 = am5radar.ClockHand.new(this.root, {
      pinRadius: 10,
      radius: am5.percent(98),
      bottomWidth: 10,
    });

    clockHand2.pin.setAll({
      fill: color2,
    });

    clockHand2.hand.setAll({
      fill: color2,
    });

    var bullet2 = axisDataItem2.set(
      "bullet",
      am5xy.AxisBullet.new(this.root, {
        sprite: clockHand2,
      })
    );

    xAxis2.createAxisRange(axisDataItem2);

    axisDataItem2.get("grid").set("forceHidden", true);
    axisDataItem2.get("tick").set("forceHidden", true);

    // Add legend
    var legend = chart.children.push(
      am5.Legend.new(this.root, {
        layout: this.root.verticalLayout,
        clickTarget: "none",
        x: am5.percent(60),
        centerX: am5.percent(50),
        layout: this.root.verticalLayout,
        paddingTop: 8,
      })
    );

    legend.labels.template.setAll({
      fontSize: 13,
    });
    legend.data.setAll([axisDataItem1, axisDataItem2]);

    function getFields(input, field) {
      var output = [];
      for (var i = 0; i < input.length; ++i) output.push(input[i][field]);
      return output;
    }

    // Animate values
    var value1 = +this.props.idsYou.toFixed(2);

    axisDataItem1.animate({
      key: "value",
      to: value1,
      duration: 1000,
      easing: am5.ease.out(am5.ease.cubic),
    });

    label1.set("text", value1);

    var value2 = +this.props.idsThem.toFixed(2);

    axisDataItem2.animate({
      key: "value",
      to: value2,
      duration: 1000,
      easing: am5.ease.out(am5.ease.cubic),
    });

    label2.set("text", value2);

    chart.appear(1000, 100);
  }

  componentDidMount() {
    var root = am5.Root.new("chartdivgauge");
    this.root = root;
    this.makechart();
  }

  componentDidUpdate(prevProps, prevState) {
    this.root.dispose();
    var root = am5.Root.new("chartdivgauge");
    this.root = root;
    this.makechart();
  }

  componentWillUnmount() {
    if (this.root) {
      this.root.dispose();
    }
  }

  render() {
    return (
      <div>
        <div class="flipcard">
          <div class="flipcard-wrap">
            <div class="card card-front">
              <img src={dei} alt="" class="card-img-top"></img>
              <div class="card-body">
                <h3>IDS Score</h3>
                <Divider></Divider>
                <MakeComparison
                  you={this.props.idsYou}
                  them={this.props.idsThem}
                  ceil={30}
                  descrip={"IDS"}
                />
                <br></br>
              </div>
            </div>
            <div class="card card-back">
              <div class="card-body">
                <div>
                  <br></br>
                  <br></br>
                  <br></br>

                  <h2>{+this.props.idsYou.toFixed(2)}</h2>

                  <div
                    id="chartdivgauge"
                    style={{ width: "100%", height: "400px" }}
                  ></div>
                  <br></br>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Gauge;
