// Core libraries: React, React Bootstrap
import React, { Component } from "react";

import { Icon } from "@rsuite/icons";
import { FaAngleUp, FaAngleDown, FaEquals } from "react-icons/fa";

class MakeComparison extends Component {
  constructor(props) {
    super(props);
    this.state = {
      you: 0,
      them: 0,
      descrip: "IDS",
      ceil: 30,
      diff: 0,
    };
    this.refresh = this.refresh.bind(this);
  }

  refresh() {
    this.setState({
      you: this.props.you,
      them: this.props.them,
      descrip: this.props.descrip,
      ceil: this.props.ceil,
      diff: +(this.props.you - this.props.them).toFixed(2),
    });
  }
  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {}

  render() {
    if (!this.props.you) {
      return <div></div>;
    }

    var diff = +(this.props.you - this.props.them).toFixed(2);

    if (diff > 0) {
      return (
        <div>
          <span>
            <h3
              style={{
                color: "green",
                display: "inline",
              }}
            >
              {+this.props.you.toFixed(2)}
            </h3>
            <h6 style={{ color: "grey", display: "inline" }}> out of </h6>
            <h6 style={{ color: "grey", display: "inline" }}>
              {this.props.ceil}
            </h6>
          </span>

          <br></br>
          <br></br>

          <p style={{ fontSize: "16px" }}>
            <Icon as={FaAngleUp} size="2em" style={{ color: "green" }} />
            Your {this.props.descrip} score is <b>{diff}</b> points higher than
            industry average.
          </p>
          <br></br>
        </div>
      );
    } else if (diff < 0) {
      return (
        <div>
          <span>
            <h3
              style={{
                color: "firebrick",
                display: "inline",
              }}
            >
              {+this.props.you.toFixed(2)}
            </h3>
            <h6 style={{ color: "grey", display: "inline" }}> out of </h6>
            <h6 style={{ color: "grey", display: "inline" }}>
              {this.props.ceil}
            </h6>
          </span>

          <br></br>
          <br></br>

          <p style={{ fontSize: "16px" }}>
            <Icon as={FaAngleDown} size="2em" style={{ color: "firebrick" }} />
            Your {this.props.descrip} score is <b>{diff}</b> points lower than
            industry average.
          </p>
          <br></br>
        </div>
      );
    } else {
      return (
        <div>
          <span>
            <h3
              style={{
                color: "black",
                display: "inline",
              }}
            >
              {Math.round(this.props.you)}
            </h3>
            <h6 style={{ color: "grey", display: "inline" }}> out of </h6>
            <h6 style={{ color: "grey", display: "inline" }}>
              {this.props.ceil}
            </h6>
          </span>

          <br></br>
          <br></br>

          <p style={{ fontSize: "16px" }}>
            <Icon
              as={FaEquals}
              size="1em"
              style={{ color: "grey", marginRight: "6px" }}
            />
            Your {this.props.descrip} score is the same as industry average.
          </p>
          <br></br>
        </div>
      );
    }
  }
}
export default MakeComparison;
